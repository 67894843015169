import axios from 'axios';

const state = {
    sideMenu:false,
    comunas:[],
    regiones:[],
    servicios:[],
    categorias:[],

};

const getters = {
    sideMenu: state => state.sideMenu,
    comunas: state => state.comunas,
    regiones: state => state.regiones,
    servicios: state => state.servicios,
    categorias: state => state.categorias,
};

const actions = {
    CambiarMenuAction({commit}, payload){
        commit('CambiarMenu', payload)        
    },
    async initialize({commit}){
    let resp;
    let done = false;
    var t = 0;
    while (!done){
        try{
            resp = await  axios({url:window.hostname+'api/initialize', method: 'GET'})
            commit('setData', resp.data)
            done = true;
        }
        catch (e){
            console.log(e)
        }
        t+=1
        if (t>10) break;
    }

    }

};

const mutations = {
    CambiarMenu(state){
        state.sideMenu = !state.sideMenu;
    },
    setData: (state, data) => {
        state.comunas = data.comunas;
        state.regiones = data.regiones;
        state.categorias = data.categorias;
        state.servicios = data.servicios;

        /*
        state.aseguradoras = data.aseguradoras;
        state.descuentos = data.descuentos;        
        state.comunas_usadas = data.comunas_usadas;        
        state.tipos = data.tipos;
        state.instituciones = data.instituciones;
        state.costo_servicio = data.costo_servicio;
        state.descuentos = data.descuentos;
        state.costo_servicio_pendiente = data.costo_servicio_pendiente;
        */
    },

};

export default{
    state,
    getters,
    actions,
    mutations,
};