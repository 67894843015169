<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        <h2 style="margin-bottom:20px;">App <b-icon icon="phone"></b-icon></h2>
        A continuación puedes obtener el link de la última versión de la aplicación para teléfonos <b>android</b> para profesionales.
        <br/>
        Corresponde a la <b> versión {{version}}</b>, que fué actualizada el <b>{{new Date(fecha_actualizacion.a, fecha_actualizacion.m-1, fecha_actualizacion.d).toLocaleDateString("es-ES", { year: 'numeric', weekday:'long', month: 'long', day: 'numeric' })}}</b>.
        <br/>
        <br/>

        <a :href="link">
          <button style="margin-right:15px; margin-bottom:10px;" class="btn btn-std" > <b-icon icon="download"></b-icon> Descargar aplicación</button>
        </a>
        <button class="btn btn-std" style="margin-bottom:10px;" v-on:click="copiar()"> <b-icon icon="clipboard"></b-icon> Copiar Link</button>

        
      </div>

    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:false,
      link:'',
      version:'',
      fecha_actualizacion:{},

    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
    
  },

  methods:{
    ...mapActions(['initialize']),

    copiar() {

        navigator.clipboard.writeText(this.link);
        this.$notify({duration:5000, group: 'foos', title: 'Éxito', text: '¡Se ha copiado el enlace!', type: 'success'})


    },

    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.get(window.hostname+'api/admin/obtener_app',)
        .then(response => 
        {
            this.loaded = true;
            this.link = response.data.link;
            this.version = response.data.version;
            this.fecha_actualizacion = response.data.fecha_actualizacion;
   
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
      }
    },
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

