<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    
    <div class="main-container">
          
      <div class="articulo-principal">
        
        <div style=" position:relative;">   

<div class="side-map" style="position:absolute; overflow-y: scroll; padding:30px 0px; height:Calc(100vh - 120px); width:400px; background-color:white; z-index:9999; top:20px; right: 20px; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; border-radius:5px;">
  <div v-if="profesionales.length > 0 && this.socket.connected">
  <div style="padding: 0px 20px;">
  <h5  style="text-align:left; margin-bottom:5px; font-weight:700;">Profesionales en ruta  </h5>

  <div style="text-align:left; font-size:14px;">Actualmente hay {{profesionales.length}} conectados. </div>
  
  <hr />
  </div>
  <div style="">
  <div style="text-align:left; width:100%;" class="profesionales" v-for="p in profesionales" @mouseenter="listMouseOver(p)" @mouseleave="listMouseLeave(p)">

    <div style="margin-bottom:20px; padding: 0px 20px;">
    <!--<div style="display:inline;"> {{p.id}} </div>
    -->
    <div style="display:inline; color:rgb(7, 136,224);" v-if="containsKey(p, 'nombre')">  {{p.nombre}}</div>
    <div style="display:inline; color:rgb(7, 136,224);" v-if="containsKey(p, 'apellido')">  {{p.apellido}} - </div>
    <div style="display:inline;" v-if="containsKey(p, 'email')"> {{p.email}}</div>
    <div style="display:inline-block; font-size:14px; font-style: italic;" v-if="containsKey(p, 'ultima_vez')"> Última actualización hace {{getTimeDifference(p.ultima_vez) }}.</div>
    <button v-on:click="goto(p.lat, p.lon)" style="display:block; margin-top:4px;" class="btn btn-ir">Ver en el mapa</button>
    </div>
  </div>
</div>


  </div>
  <div style=" padding-top:70px;" v-else>
    
<div style="margin: auto auto;" class="loader"></div>
<div style="color:rgb(140, 140, 140); margin-top:10px; font-style: italic; text-align: center;">Cargando...</div>

  </div>

</div>


<!--
{{profesionales}}
-->
<div  ref="mapa" id="mapa"  class="map" style="" >

   <l-map 
    :options="{attributionControl:false, zoomControl: false}"
    @update:zoom="zoomUpdate"
    @update:center="centerUpdate"
    :zoom="zoom" 
    :center="center">
    <l-tile-layer :url="url"></l-tile-layer> 
    <l-circle-marker v-if="circulo.mostrando"
    :key="key"
    :lat-lng="[circulo.lat, circulo.lon]"
    :radius="25"
    :pane="'shadowPane'">
    </l-circle-marker>



            <l-moving-rotated-marker
      v-for="p in profesionales_filtrados"
      :key="p.id"
      :ref="'marker_'+p.id"

    @mouseenter="openPopup"
    @mouseleave="closePopup"
      :lat-lng="[p.lat, p.lon]"
      :visible="true"
      
      :duration="2000"
      :rotationAngle="0"
      :icon="icon"
  >
    <l-popup :id="p.id"
    :options="{ autoClose: false, autoPan: false, closeOnClick: false, closeButon: false, maxWidth: 'auto'}">
    <span>
      {{p.nombre}} {{p.apellido}}
    </span>
    </l-popup>
        </l-moving-rotated-marker>
    <l-control-zoom position="topleft"  ></l-control-zoom>
   </l-map>
</div>
</div>

        
      </div>
      
    </div>

  </div>



</div>
</template>

<script>
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
  import * as L from 'leaflet'
  import { LMap, LTileLayer, LMarker, LGeoJson, LCircle, LCircleMarker, LPopup, LIcon, LControlAttribution, LRectangle, LPolygon,LPolyline, LControlZoom } from "vue2-leaflet";
  import "leaflet/dist/leaflet.css";
  import io from 'socket.io-client';
  import {mapGetters, mapActions} from 'vuex';
  import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker'
  
  export default {
    name: 'HelloWorld',
      components: {
        SideNav,
        TopNav,
        LMap, 
        LTileLayer, 
        LMarker, 
        LCircle, 
        LIcon,
        LControlAttribution,
        LControlZoom,
        LMovingRotatedMarker,
        LRectangle,
        LPolygon,
        LPolyline,
        LPopup,
        LCircleMarker,
      },
    data() {
      return {
          icon: L.icon({
            iconUrl: require('@/assets/persona2.png'),
            iconSize: [30,30],
            iconAnchor: [15,15],
            popupAnchor: [0,-7]
        }),
        circulo:{'mostrando':false, 'lat':0, 'lon':0},
        profesionales:[],
        profesionales_filtrados:[],
        key:1,
        socket:undefined,
        zoom:12,
        center: L.latLng(-33.438926, -70.651746),
        currentCenter: L.latLng(-33.438926, -70.651746),
        url:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }
    },
    methods:{
      ...mapActions(['loginAdmin', 'logoutAdmin']),
        openPopup: function (event) {
          //console.log("LOL")
            event.target.openPopup();
        },
        closePopup: function (event) {
            event.target.closePopup();
        },
  
      getTimeDifference(t){
        let diff = Date.now() - new Date(t).getTime()
        let minutos = Math.floor(((diff % 86400000) % 3600000) / 60000)
        let segundos = Math.floor((((diff % 86400000) % 3600000) % 60000)/1000)
        if (minutos > 0)
          return minutos+' minutos y '+segundos+ (segundos > 1 && segundos != 0 ? ' segundos' : ' segundo')
        return segundos+ (segundos > 1 && segundos != 0 ? ' segundos' : ' segundo')
  
      },
      containsKey(obj, key ) {
          return Object.keys(obj).includes(key);
      },
      listMouseOver: function(x){
        this.circulo.lat = x.lat;
        this.circulo.lon = x.lon;
        this.circulo.mostrando = true;
  
      },
      listMouseLeave: function(x){
        this.circulo.mostrando = false;
  
      },
      goto: function(lat, lon){
        if (lat != null && lon != null)
          this.center =  L.latLng(lat+(Math.random()*0.000001), lon+(Math.random()*0.000001));
      },
      zoomUpdate: function(zoom){
        this.currentZoom = zoom;
      },
        centerUpdate: function(center){
        this.currentCenter = center;
      },
  
  
    },
    destroyed() {
      this.socket.disconnect();

  },
    watch:{
      profesionales: function(val){
        this.key += 1;
        this.profesionales_filtrados = []
        let ret = []
        for(var z of this.profesionales)
        {
          if(z.lat != null && z.lon != null)
          this.profesionales_filtrados.push(z)
  
        }
        //this.profesionales_filtrados = ret
        this.$forceUpdate();
      }
    },
    computed:{
      ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'comunas_usadas', 'token']),
    },
    mounted(){
      this.socket = io(window.socket,   {extraHeaders: {
      "token": this.token,
      "tipo": "admin",
      "usuario": JSON.stringify(this.admin_datos)
    }})
    //console.log("token: ", this.token)
      this.socket.on("connect", ()=>{
        console.log("Se conecto")
      })
      this.socket.on("emitir_info_admin", (data) => {
        this.profesionales = JSON.parse(data);
      });
  
      setInterval(() => {
      this.socket.emit('escuchar_info_admin', this.token)
  
      }, 3000);
    }
  
  }
  </script>
  
  
  <style scoped>

    .column-right{
      padding: 70px 0px 0px 250px;

    }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .btn-ir{
    background-color: white;
    color:rgba(20, 134, 231, 1);
    font-weight:700;
    border: 3px solid rgba(20, 134, 231, 1);
    padding: 0px 13px;
    border-radius: 10px;
    cursor: pointer;
    transition:0.1s;
  
  
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .btn-ir:hover{
    color:rgba(20, 134, 231, 1);
  
  }
  
    .map{
      
        /*-moz-animation: 
        height: 320px;
        width: 320px;
        */
        /*transform: translate(0px, -120px);*/
        height: Calc(100vh - 70px);
        /*min-width: 400px;*/
        min-width: 400px;
  
        margin-right:0 !important;
    }
  
  td{
    padding: 5px 10px 0px 5px;
  }
  @media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
.side-map{
  display: none;
}
}
  
  </style>