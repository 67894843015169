<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  <div class="column-right">
    <div class="main-container">
        <div class="">
    <div v-if="loaded">

<h4 style="margin-bottom:20px; text-align:left;">Modificar un horario <b-icon icon="pen"/></h4>



         



 <h4 style="font-size:16px;"> <span class="numerito">1</span> Horas, dias y vigencia</h4>
                      <table style="margin: 0; margin-bottom:20px">
  
                        <td style="vertical-align:middle; padding-right:20px;">
                          <tr>
                          Inicio:
                            </tr>
                            <tr>
                          <select v-model="agregando_horario.inicio">
                            <option v-for="v in Math.round(1440/15)"  :value="(v-1)*15">{{timeConvert((v-1)*15)}}</option>
                          </select>
                          </tr>
                        </td>
                        <td style="vertical-align:middle; padding-right:20px;">
                          <tr>
                          Termino: 
                          </tr>
                          <select v-model="agregando_horario.termino">
                            <option  v-for="v in Math.round((1440-agregando_horario.inicio)/periodo)" :value="(v)*periodo + agregando_horario.inicio">{{timeConvert((v)*periodo + agregando_horario.inicio)}}</option>
                          </select>
                        </td>
                      
                      <td style="vertical-align:middle;">
                        <table>
                          <tr>
                            <td style="text-align:center; width:30px;" v-for="x in dias">
                              {{x}}
                            </td>
                          </tr>
                          <tr>
                          <td  style="text-align:center;" v-for="x in agregando_horario.repeticion">
                              <input   type="checkbox" v-model="x.value" :true-value="1" :false-value="0">
                          </td>
                          </tr>
                        </table>
                      </td>
                      </table>
                      Vigencia: 
                      <div v-on:click="indefinido = 1" :class="{'vig-select': indefinido == 1}" class="vig">Indefinido</div>
                      <div v-on:click="indefinido = 2" :class="{'vig-select': indefinido != 1}" class="vig"> Plazo fijo</div>
                      
                      <div>
                      <div :style="{'opacity': indefinido == 1 ? '0.2': '1'}" style="margin-top:10px; padding:10px 0px;  position:relative; display:inline-block;">
                        <div v-if="indefinido == 1" style=" position:absolute; height:100%; width:100%; cursor: not-allowed;"> </div>
                         <div>Plazo:</div>
                          Desde <input id="x2" type="date" v-model="dt_dia" > 
                          Hasta: <input id="x2" type="date" v-model="dt_dia2" >
                        </div>
                      </div>









                      

              <div  class="d-comunas">
                 <h4 style="font-size:16px;"> <span class="numerito">2</span> Servicios</h4>
                  Servicios
                  <span style="color:#FF8C00;">
                  {{servicios_selected.length > 0 ? servicios_selected.length > 1 ? '('+servicios_selected.length+' agregados)' : '('+servicios_selected.length+' agregado)' : ''}}
                </span>
                <div style="display:inline-block; margin-bottom:8px;">

                  <div style="border: 1px solid rgba(0,0,0,0.7); position:relative; padding:1px; border-radius:5px; ">
                    <b-icon style="position:absolute;right:5px; top:5px;" icon="search"></b-icon>
                  <input placeholder="Escribe para buscar..." style=" outline:none; border:none;" type="text" v-model="servicios_buscados">
                </div>

                  <div style="margin:0; position:relative; text-align:center; width:200px;">
                      <div v-if="servicios_buscados != ''" style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;">
                      <div v-on:click="agregar_servicios(r.item.id)" style="cursor:pointer; text-align:left; padding:2px 5px;" v-for="r in resultado_fuse_servicio.slice(0,10)">
                        <b-icon style="margin-right:4px;" icon="plus-circle"></b-icon>
                          <span >{{r.item.nombre}}<b-icon icon="check" v-if="servicios_selected.includes(r.item.id)"/> </span>
                      </div>
                      </div>
                  </div>
                </div>
                  <div style="font-weight:700;" v-if="servicios_selected.length > 0">
                  <div v-on:click="agregar_servicios(c)" style="cursor:pointer; border: 2px solid rgba(0,0,0,0.6); border-radius:10px; padding: 2px 5px; display:inline-block; margin-right:4px;" v-for="c in servicios_selected">
                    {{servicios.filter(x=>{return x.id == c})[0].nombre}} <b-icon icon="x-circle"></b-icon>
                  </div>
                  
                  </div>  
                          
              </div>




              <div  class="d-comunas">
                 <h4 style="font-size:16px;"> <span class="numerito">3</span> Localización</h4>
                  Comunas 
                  <span style="color:#FF8C00;">
                  {{comunas_selected.length > 0 ? comunas_selected.length > 1 ? '('+comunas_selected.length+' agregadas)' : '('+comunas_selected.length+' agregada)' : ''}}
                </span>
                <div style="display:inline-block; margin-bottom:8px;">

                  <div style="border: 1px solid rgba(0,0,0,0.7); position:relative; padding:1px; border-radius:5px; ">
                    <b-icon style="position:absolute;right:5px; top:5px;" icon="search"></b-icon>
                  <input placeholder="Escribe para buscar..." style=" outline:none; border:none;" type="text" v-model="comunas_buscadas">
                </div>

                  <div style="margin:0; position:relative; text-align:center; width:200px;">
                      <div v-if="comunas_buscadas != ''" style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;">
                      <div v-on:click="r.item.region != undefined ? agregar_comunas(r.item.id) : agregar_region(r.item.id)" style="cursor:pointer; text-align:left; padding:2px 5px;" v-for="r in resultado_fuse_comuna.slice(0,10)">
                        <b-icon style="margin-right:4px;" icon="geo-alt"></b-icon>
                          <span >{{r.item.Nombre}}<b-icon icon="check" v-if="comunas_selected.includes(r.item.id)"/> </span>
                      </div>
                      </div>
                  </div>
                </div>
                  <div style="font-weight:700;" v-if="comunas_selected.length > 0">
                  <div v-on:click="agregar_comunas(c)" style="cursor:pointer; border: 2px solid rgba(0,0,0,0.6); border-radius:10px; padding: 2px 5px; display:inline-block; margin-right:4px;" v-for="c in comunas_selected">
                    {{comunas.find(x=>{return x.id == c}).Nombre}} <b-icon icon="x-circle"></b-icon>
                  </div>
                  
                  </div>  
                          
              </div>















           <h4 style="font-size:16px; margin-top:15px;"> <span class="numerito">4</span> Otras opciones</h4>

  
            <div style="margin-bottom:20px; margin-top:15px;">
              Anticipación necesaria (en horas):
              <select v-model="anticipacion">
                <option v-for="v in 25" :value="(v-1)*60">{{timeConvert((v-1)*60)}}</option>
              </select>
            </div>    

            <!--
                                <div style="margin-bottom:20px">
                                  Anticipación mínima (en horas):
          <select v-model="anticipacion_minima">
            <option v-for="v in 25" :value="(v-1)*60">{{timeConvert((v-1)*60)}}</option>
          </select>
               </div>
                           <div style="margin-bottom:20px">
                                  Anticipación autoconfirmación (en horas): 
          <select v-model="anticipacion_autoconfirmacion">
            <option v-for="v in 25" :value="v-1">{{timeConvert(((v-1)*60))}}</option>
          </select>
               </div>
-->
               <button v-on:click="agregar_horario()" class="btn btn-std">Agregar Horario</button>   

                  
  </div>
             </div>
</div>






  </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import InputColorPicker from 'vue-native-color-picker'

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    InputColorPicker,
  },
  data() {
    return {
      profesional:{},
      agregando_horario:{inicio:-1, termino:-1, repeticion:[{value:0},{value:0},{value:0},{value:0},{value:0},{value:0},{value:0}]},
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      periodo:60,
      periodos_posibles:[15,30,45,60,90,120],
      loaded:true,

      comunas_selected:[],
      servicios_selected:[],

      resultado_fuse_comuna:[],
      resultado_fuse_servicio:[],

      comunas_buscadas:'',
      servicios_buscados:'',

      anticipacion_minima:0,
      anticipacion_autoconfirmacion:0,
      nombre: '',
    
      editando_categorias:[],
      anticipacion:1440,
      

      dt_dia:'',
      dt_dia2:'',
      indefinido:1,


    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    agregar_region(rid){
      console.log("AAAA")
      let a_agregar = this.comunas.filter(z=>{return z.region == rid})
      for(var c of a_agregar){
        console.log("agregando a ", c.id)
        if(!this.comunas_selected.includes(c.id))
          this.comunas_selected.push(c.id)

      }
      this.comunas_buscadas = ''

    },
    agregar_comunas(cid){
        this.comunas_buscadas = ''
        if(!this.comunas_selected.includes(cid))
          this.comunas_selected.push(cid)
        else
          this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1)

    },
    agregar_servicios(cid){
        this.servicios_buscados = ''
        if(!this.servicios_selected.includes(cid))
          this.servicios_selected.push(cid)
        else
          this.servicios_selected.splice(this.servicios_selected.indexOf(cid), 1)

    },
   timeConvert2 (n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  + rhours + " h " + rminutes + " m.";
    },

 

        timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    


        agregar_horario(){

      var error_msg = ''

      if(this.agregando_horario.inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(this.agregando_horario.termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(this.agregando_horario.inicio > this.agregando_horario.termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < this.agregando_horario.repeticion.length; i++)
        x += this.agregando_horario.repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '
      

      if(this.servicios_selected.length == 0)
        error_msg = 'Debes agregar al menos un servicio'
      if(this.comunas_selected.length == 0)
        error_msg = 'Debes agregar al menos una comuna'

      if(error_msg == ''){
        
        axios.post(window.hostname+'api/admin/agregar_horario', 
        {profesional_id: this.$route.params.id,
          inicio: this.agregando_horario.inicio,
          termino: this.agregando_horario.termino,
          repeticion:x,
          servicios:this.servicios_selected,
          comunas:this.comunas_selected,
          anticipacion:this.anticipacion,
          vigencia_desde:this.dt_dia,
          vigencia_hasta:this.dt_dia2,

          anticipacion_minima:this.anticipacion_minima,
          anticipacion_autoconfirmacion:this.anticipacion_autoconfirmacion,
          tipo:this.indefinido

          } )
        .then(resp=>{

          this.$notify({duration:5000, group: 'foos', title: 'Horario agregado', text: '¡Se ha agregado el horario!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{ 
          this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        console.log("LOL", error_msg)
        this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: error_msg, type: 'error'})
      }

    },

  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'servicios']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas.concat(this.regiones), {  keys: ['Nombre']})
    this.fServicios = new Fuse(this.servicios, {  keys: ['nombre']})
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    servicios_buscados(val){
      this.resultado_fuse_servicio = this.fServicios.search(val)

    },
  }

}
</script>


<style scoped>

.input-cont{
  position:relative;
  text-align:left;
  max-width:400px;
  margin-bottom:40px;
}
.input-cont input{
  padding: 4px;
  border:none;
  border-bottom:1px solid rgba(0,0,0,0.4);
  width:100%;
  outline:none;
}
.input-label{
  position:absolute;
  top:3px;
  left:6px;
  pointer-events: none;
  color:rgba(0,0,0,0.6);
  transition: 0.2s;
}
.subido{
  top:-12px;
  left:0px;
  font-size: 12px;
  color:#FF8C00;
}
.subido2{
  top:-16px;
  left:-84px;
  font-size: 12px;
  color:#FF8C00;
}

.d-comunas{
  margin-top:15px;
}

 .vig{
   display: inline-block;
   border: 2px solid #FF8C00;
   padding: 4px 10px;
   margin-right:5px;
   border-radius: 5px;
   color:#FF8C00;
   cursor: pointer;
 }
 .vig-select{
   background: #FF8C00;
   color:white;
 }

 .numerito{
   background: rgba(0,0,0,0.8); color:white; font-size:13px; font-weight:700; border-radius:100px; padding: 1px 6px;
 }
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.column-right{
  background-color: rgb(242, 247, 251);
  min-height:100vh;
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

