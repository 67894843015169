<template>

<div class="main">
<div class="container">
  <center>
    
    <div class="middle">
      
      <div id="login">

        <form action="javascript:void(0);" method="get">

          <fieldset class="clearfix">

            <p><span class="bi bi-person-fill"></span><input v-model="usuario" type="text" Placeholder="Correo electrónico"></p>

            <p><span class="bi bi-lock-fill"></span><input v-model="password" type="password" Placeholder="Contraseña" ></p>

            <div>
              <span style="width:48%; text-align:left;  display: inline-block;"><a class="small-text" href="#">Olvidé mi contraseña</a></span>
              <button @click="sendLogin()" style="min-width:120px;" class="btn btn-entrar">Entrar <div  style="display:inline-block; transform:translateY(0px); "><div v-if="loading" class="spinner-border text-light " style="margin: 0 auto; height:15px; width:15px; margin-top: "  role="status" /></div></button>
            </div>

          </fieldset>
          <div class="clearfix"></div>
        </form>

        <div class="clearfix"></div>

      </div> <!-- end login -->
      <div class="logo">
        <img style="max-height:150px; position:absolute; top:50%; transform:translate(-50%, -50%);" src="@/assets/logo.png" alt="">

        <div class="clearfix"></div>
      </div>

    </div>
  </center>
</div>
<div style="width: 100%; position:fixed; background:rgba(0,0,0,0.9); bottom:0px; height:40px; line-height:40px; color:#fff;">
  Jobbing.cl | Términos y condiciones
        
        
</div>
</div>



</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      usuario:'',
      password:'',
      loading:false,
    }
  },
  methods: {
    ...mapActions(['loginpro']),
    sendLogin(){
      if(this.loading)
        return;
      this.loading = true;
      this.loginpro({email:this.usuario, password:this.password})
      .then(r=>{
        this.loading = false;
        this.$router.push('/Home')
        this.$notify({
          group: 'foos',
          title: '¡Éxito!',
          type: 'success',
          text: '¡Has iniciado sesión!',
        });
      })
      .catch(e=>{
        this.loading = false;
        console.log(e.response.data)
        this.$notify({
          group: 'foo',
          title: '¡Error!',
          type: 'warn',
          text: e.response.data,
        });
      })

    },
  },
}
</script>
<style lang="scss"  scoped>
  .btn-entrar{
    background-color: #FF8C00  !important;
    color: #fff !important;
    display: inline-block !important;
    transform: translateY(-10px);
    padding: 5px 25px !important;
  }
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

/* general */

* {
  box-sizing: border-box;
  margin: 0px auto;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

.main {
  background: #0264d6;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #0264d6 1%,
    #1c2b5a 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(1%, #0264d6),
    color-stop(100%, #1c2b5a)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #0264d6 1%,
    #1c2b5a 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #0264d6 1%,
    #1c2b5a 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #0264d6 1%,
    #1c2b5a 100%
  );
  background: radial-gradient(ellipse at center, #0264d6 1%, #1c2b5a 100%);

  height: calc(100vh);
  width: 100%;
}

.main {
  color: #1c2b5a;
  font-size: 14px;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.small-text {
  color: #ddd;
}

.small-text:hover {
  color: #fff;
}

input {
  border: none;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0;
  -webkit-appearance: none;
}

p {
  line-height: 1.5em;
}

.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.container {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Login */

#login form {
  width: 250px;
}
#login,
.logo {
  display: inline-block;
  width: 40%;
}
#login {
  border-right: 2px solid #fff;
  padding: 0px 22px;
  width: 59%;
}
.logo {
  color: #fff;
  font-size: 50px;
  line-height: 125px;
}

#login form span.bi {
  background-color: #fff;
  border-radius: 3px 0px 0px 3px;
  color: #000;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

#login form input {
  height: 50px;
}
fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}
#login form input[type="text"],
input[type="password"] {
  background-color: #fff;
  border-radius: 0px 3px 3px 0px;
  color: #000;
  margin-bottom: 1em;
  padding: 0 16px;
  width: 200px;
}

#login form input[type="submit"] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #000000;
  color: #eee;
  font-weight: bold;
  /* margin-bottom: 2em; */
  text-transform: uppercase;
  padding: 3px 10px;
  height: 30px;
}

#login form input[type="submit"]:hover {
  background-color: #0264d6;
  color: #fff;
}

#login > p {
  text-align: center;
}

#login > p span {
  padding-left: 5px;
}
.middle {
  position:absolute;
  top:50%;
  left:50%;
  display: flex;
  width: 600px;
  transform: translate(-50%, -50%);
}
input {
  outline: none;
}



@media (max-width: 800px) {
#login {
  border-right: none;
  padding: 0px 22px;
  width: 59%;
}
.logo {
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  top:-100px;
}

}
</style>