<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">

      <div style="position:absolute; right:0px; top:10px; padding:20px; ">
        Desde <input
          id="x2"
          v-model="dt_dia"
          type="date"
        > Hasta: <input
          id="x2"
          v-model="dt_dia2"
          type="date"
        >
      </div>
      
      <div style="height:60px;"></div>
      <div style="display:flex; gap:20px;">



        <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #0074D9; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="person"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Clientes unicos
        </div>
<div style="font-size: 25px;">
<!--   {{ numero_clientes_con_pago }} -->
  {{ cliente_unicos.length }}
</div>
</div>
        </div>

        
        
      </div>


      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #FF851B; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="chat-right-text"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total de solicitudes
        </div>
<div style="font-size: 25px;">
  {{ solicitudes.length }}
</div>
</div>
        </div>

        
        
      </div>

      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #7FDBFF; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="clipboard"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total ordenes
        </div>
<div style="font-size: 25px;">
  {{ ordenes_calculated.length }}
</div>
</div>
        </div>

        
        
      </div>


      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #3D9970; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="currency-dollar"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total ingreso
        </div>
<div style="font-size: 25px;">
  ${{Intl.NumberFormat('de-DE').format(this.ordenes.reduce((a,b) => {return a + b.monto_pagado}, 0)) }}
</div>
</div>
        </div>

        
        
      </div>


      </div>


      <h4>Gráfico de ingresos</h4>
      <div v-if="loaded" class="articulo-principal">
        <div style="height:50px"></div>
        <line-chart
        v-if="true"
        :key="key+keyMain"
        :height="430"
      
        style=""
        :data="graficoLineaSumaPagos"
        :options="optionsLinea"
      />
<!--       <div style="background-color: rgba(46, 204, 64, 0.1); border-radius: 4px; color:#2ECC40; display: inline-block; padding: 4px 14px;">Total ingreso diario cobrado: <b>${{Intl.NumberFormat('de-DE').format(this.ordenes.reduce((a,b) => {return a + b.monto_pagado}, 0)) }}</b></div>  -->
        <br/>
        <br/>
      <h4>Gráfico de Solicitudes</h4>

      <line-chart
        v-if="true"
        :key="key+keyMain"
        :height="430"
      
        style=""
        :data="graficoNumeroSolicitudes"
        :options="optionsLinea"
      />

      <WeekDensityChart 
      :ordenes="ordenes.filter(o=>{return o.estado != 0})" :bgColor="'rgb(20, 134, 231)'"
    />
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por servicio
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoServicios"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
    
      <div>
        <h4>Clientes</h4>
        <div v-for="c in cliente_unicos"> {{ new Date(c.fecha.a, c.fecha.m, c.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }} {{ c.nombre }} </div>
      </div>
        
      </div>

      
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import WeekDensityChart from '@/components/WeekDensityChart.vue';
import LineChart from "./LineChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    LineChart,
    PieChart,
    WeekDensityChart,
    // BarChart,
  },
  data() {
    return {
      loaded:true,
      dt_dia:'',
      dt_dia2:'',
      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},
      ordenes:[],
      ordenes_calculated:[],

      solicitudes:[],

      id_orden:0,
      popup: false,
      mouseX:0,
      mouseY:0,
      resultado:'',
      estados:['Pendiente', 'Agendado',  'Confirmado', 'Atendido', 'Resultados Entregados', 'Evaluado'],
      key:0,
      keyMain:0,

      graf_sol:[],
      
      chartOptions: {
        responsive:true,
        hoverBorderWidth: 20,
        title: {
          display: false,
          text: 'Actividad en la próxima'
        },
                pieceLabel: {
            mode: 'value'
        },
        legend:{display:true, position:'right'},
      },
      optionsLinea: {
            maintainAspectRatio: false,
            height: 70,
            responsive: true,
            title:{
                display: false,
                text: "Chart.js Time Scale"
            },
            scales:{
                xAxes: [{
                    type: "time",
                    time: {unit: 'day', displayFormats: {day: 'MMM DD' }
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Día'
                }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  },
                    scaleLabel: {
                        display:     true,
                        labelString: 'Monto'
                    }
                }]
            }
      },

    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    groupBy (xs, key) {

      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    extractDateMonthYear(isoDateString) {
  // Parse the ISO string
  const date = new Date(isoDateString);

  // Get the year, month, and day
  const year = date.getFullYear();
  // Adding 1 to getMonth() to convert from 0-based index to month number
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Format month and day to ensure they are in 'MM' or 'DD' format
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  // Return the formatted string in "MM/DD/YYYY" format
  return `${formattedMonth}/${formattedDay}/${year}`;
    },

    get_ordenes(){      
      if (this.desde.a != 0 && this.hasta.a != 0)
      {
        axios.post(window.hostname+'api/admin/get_reporte', 
        {dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a})
        .then(response => 
        {
          var dt_ins;
          for(var sol of response.data.solicitudes){
            
            sol.fecha_int = this.extractDateMonthYear(sol.fecha_creado_iso)
          }
          this.solicitudes = response.data.solicitudes;
          
          this.ordenes = response.data.ordenes
          function compare(a, b) {
            let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d)
            let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d)
            if ((a.urgente && a.estado == 1)  && !(b.urgente && b.estado == 1)){
              return -1                
            }
            if (!(a.urgente && a.estado == 1) && (b.urgente && b.estado == 1)){
              return 1
            }
            if(da > db){
              return 1
            }
            if (da < db){       
              return -1
            }
            return 0
          }
          this.ordenes_calculated = []
          
          var ins = {}
          var hoy = new Date();
          var dia = new Date();
          for (var i = 0; i < this.ordenes.length; i++){
            ins = this.ordenes[i]
            dia = new Date(ins.fecha.a, ins.fecha.m-1, ins.fecha.d)
            if ((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)) < 2*24)
              ins.urgente = true;
            else
              ins.urgente = false;
            this.ordenes_calculated.push(ins)
          }
          this.ordenes_calculated.sort(compare)
        })
        .catch(e=>{
          console.log("ERROR:", e)        
        })
    }
    }



  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'servicios']),


    cliente_unicos(){
      let clientes_ = []
      let clientes_id = []
      let ins = {}
      for (var o of this.ordenes){
        console.log(o)
        if(o.estado >= 2 && o.monto_pagado > 0 && !clientes_id.includes(o.cliente.id))
        {
          ins = o.cliente
          ins.fecha = o.fecha
          clientes_id.push(o.cliente.id)
          clientes_.push(ins)
        }

      }
      return clientes_;
  
    },

    numero_clientes_con_pago(){
      let clientes_id = []
      for (var o of this.ordenes){
        console.log(o)
        if(o.estado >= 2 && o.monto_pagado > 0 && !clientes_id.includes(o.cliente.id))
        {
          clientes_id.push(o.cliente.id)
        }

      }
      return clientes_id.length;
    },

    graficoComunas(){
      var ret = this.groupBy(this.ordenes, 'comuna_id')
      let keys = Object.keys(ret)
      var labs = []
      var data = []
      for(var k = 0; k< keys.length; k++){
        labs.push(this.comunas.filter(x=>{return x.id == keys[k]})[0].Nombre)
        data.push(ret[keys[k]].reduce((a, b)=>{return (a+b.monto || 0) }, 0))
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labs,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoCategoriasVenta(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(
          this.ordenes.reduce((a, b)=>{
                return (a+b.servicios.filter(z=>{
                  return this.servicios.filter(m=>{return m.id == z.examen_id}).length > 0 ? (this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id) : false}).reduce((c, d)=>{return c+d.precio || 0}, 0) || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoServicios2(){
      var labels = []
      var data = []
      let servicios_id = []
      for(var o of this.ordenes){
        for(var s of o.servicios){

          if(!servicios_id.includes(s.id)){
            servicios_id.push(s.servicio_id)
          }
        }

      }

      for(var s of servicios_id){
        data.push(this.ordenes.reduce((x,y) => {

          return x + y.servicios.filter(z=>{return z.id == s}).reduce((a, b)=>{
            return a + b.precio;
           },0)

        }, 0));

        if( s != 0 && s != null)
          labels.push(this.servicios.find(z=>{return z.id == s}).nombre)
        
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoServicios(){
      var labels = []
      var data = []
      let servicios_nombres = []      
      let ordenes_ = this.ordenes.filter(x=>{return x.estado >= 2})
      for(var o of ordenes_){
        for(var s of o.servicios){

    
          if(!servicios_nombres.includes(s.nombre)){
            servicios_nombres.push(s.nombre)
          }
        }

      }

      for(var s of servicios_nombres){
        data.push(this.ordenes.reduce((x,y) => {

          return x + y.servicios.filter(z=>{return z.nombre == s}).reduce((a, b)=>{
            return a + b.precio;
           },0)

        }, 0));


          labels.push(s)
        
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoEstados(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length
      let labels = this.estados
      var data = []
      for(var i = 0; i < this.estados.length; i++){
        data.push(this.ordenes.filter(x=>{return x.estado == i}).length)
      }          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ],
            data: data
          }
        ]
      }
      return graph;
    },
    
      graficoPagadoNoPagadoVenta(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let labels = ['Pagadas', 'No Pagadas']
          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }
      return graph;
    },
      graficoPagadoNoPagado(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length
      let labels = ['Pagadas', 'No Pagadas']
          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }
      return graph;
    },
    
    graficoLineaSumaPagos(){
      var ret = this.groupBy(this.ordenes, 'fecha_dt')

      var ret_fecha_creado = this.groupBy(this.ordenes, 'fecha_creado_dt')
 
      let labels1 = []
      let labels2 = []
      let ventasProgramadasTotal = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)
      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})
        if (ret.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:ret[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto_servicios || 0) }, 0)})
          labels2.push({x:fecha, y:ret[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto_pagado || 0) }, 0)})
          
        } else {
          labels1.push({x:fecha, y:0})
          labels2.push({x:fecha, y:0})
          
        }
        if (ret_fecha_creado.hasOwnProperty(fecha)) {
          ventasProgramadasTotal.push({x:fecha, y:ret_fecha_creado[fecha].reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
        } else {
          ventasProgramadasTotal.push({x:fecha, y:0})
        }
      }
      let graph = {          
        datasets: [
          {
            label: "Ingreso diario total", 
            data: labels1,
            fill: false,
            borderColor: 'rgba(255, 10, 13, 0.5)',
            backgroundColor: "rgba(255, 10, 13, 0.2)"
          },
          {
            label: "Ingreso diario cobrado", 
            data: labels2,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },
          /*
          {
            label: "Ventas programadas", 
            data: ventasProgramadasTotal,
            fill: false,
            backgroundColor: 'rgba(246, 237, 116, 0.2)',
            borderColor: 'rgba(246, 237, 116, 0.5)'
          }
          */
        ]
      }
      return graph;
    },
    graficoNumeroSolicitudes(){

      var ret = this.groupBy(this.solicitudes, 'fecha_int')

 
      let labels1 = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)

      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})
        if (ret.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:ret[fecha].length})

          
        } else {
          labels1.push({x:fecha, y:0})

          
        }

      }

      let graph = {          
        datasets: [
          {
            label: "Número de solicitudes", 
            data: labels1,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },


        ]
      }

      return graph;
    },
  },
  mounted() {
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    hoy.setDate(hoy.getDate() + 7);
    this.dt_dia2 = hoy.toISOString().split('T')[0]

  },
  watch:{
/*     solicitudes:{
      handler(newValue, oldValue){
        console.log("JAJAJAJAJA CAMBIOOO", newValue)
      var dt_;
      var date_str;
      for (let v of newValue){
        console.log(v)
        dt_ = new Date(v.fecha_creado_iso);
      }
      console.log("COMO??")

      },
    deep: true
    }, */

    dt_dia(val){
        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){
        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    ordenes(){
      this.key +=1; 
    }
  },

}
</script>


<style scoped>
.graphContainerTop{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:33%;  display:inline-block; 
}
td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
  position: relative;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

