import axios from 'axios';

const state = {

    /*datos usuario*/
    admin_datos:[],
    token: localStorage.getItem('token_auth') || '',
    isLoggedIn: false,
    /*misc*/
    admin_cargando:0,
    admin_iniciado:false,


};

const getters = {
    token: state => state.token,
    admin_datos: state => state.admin_datos,
    isLoggedIn: state => state.isLoggedIn,
    admin_iniciado: state => state.admin_iniciado,
};

const actions = {
    getpro(context, payload){
        return new Promise((resolve, reject) => {
            context.commit('startLoading')
            axios({url:window.hostname+'api/admin/getadminbytoken', method: 'GET' })
            .then(resp => {
                const user = resp.data.user
                const data ={'token_auth': localStorage.token_auth, 'user': user}
                context.commit('auth_success', data)
                context.commit('stopLoading')
                resolve(resp)
            })
            .catch(err => {
                console.log(err.response)
                context.commit('logout')
                context.commit('stopLoading')
                localStorage.removeItem('token_auth')
                reject(err)
            })
        })
    },
    logoutpro(context){
        localStorage.removeItem('token_auth')
        context.commit('logout')
    },
    loginpro(context, user){
        return new Promise((resolve, reject) => {
            context.commit('startLoading')
            axios({url: window.hostname+'api/admin/login', data: user, method: 'POST' })
            .then(resp => {
                const token = resp.data.token
                const user = resp.data.user
                const data = {'token_auth': token, 'user': user}
                localStorage.setItem('token_auth', token)
                axios.defaults.headers.common['Authorization'] = token
                context.commit('auth_success', data)
                context.commit('stopLoading')
                resolve(resp)
            })
            .catch(err => {
                context.commit('stopLoading')
                context.commit('logout')
                localStorage.removeItem('token_auth')
                reject(err)
            })
        })
    },

};

const mutations = {
    auth_success(state, data){        
        state.admin_datos = data.user
        state.token = data.token_auth
        state.isLoggedIn = true
      },
    logout(state){
        state.admin_datos = []
        state.isLoggedIn = false
        state.token = ''
      },
      startLoading: (state) => {state.cargando = 1},
      stopLoading: (state) => {state.cargando = 0},

};

export default{
    state,
    getters,
    actions,
    mutations,
};