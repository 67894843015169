<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        <button v-on:click="$router.push({'name':'BlogNuevoPost'})" class="btn-std-2">Nuevo post en el Blog</button>
        <div style="position:absolute; right:0px; top:0px;"> 
          Desde <input id="x2" type="date" v-model="dt_dia" >
        </div>
     
        <table class="tablex">
    <thead>
        <tr>

            <th>ID</th>
            <th>Fecha</th>
            <th>Autor</th>
            <th>Título</th>
            <th>Tags</th>
            <th></th>

        </tr>
    </thead>
    <tbody>
      <tr v-for="p in posts">
        <td>{{ p.id }}</td>
        <td >{{new Date(p.fecha_creado).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</td>
        <td>{{ p.administrador.nombre }} {{ p.administrador.apellido }}</td>
        <td>{{ p.titulo }}</td>
        <td><div v-for="e in p.etiquetas">{{ categorias.find(x=>x.id == e).nombre }}</div></td>
        <td>
        <b-icon v-on:click="$router.push({'path':'/superadmin/EditarPost/'+p.id})" icon="pen" style="color: #0074D9; cursor: pointer;"></b-icon>
        <b-icon v-on:click="borrar_post(p.id)" icon="trash" style="color: #FF4136; cursor: pointer;"></b-icon>
        </td>

      </tr>
      </tbody>
      </table>
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:true,
      dt_dia:'',
      posts:[],
    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    initialize_(){

this.loaded = false;
axios.post(window.hostname+'api/superadmin/initialize_blog_posts',
{desde:this.dt_dia}
)
    .then(response => 
    {
      this.posts = response.data.posts
      this.loaded = true;

    })

.catch(e=>{
    this.loaded = true;
    console.log("Error al cargar...", e)
})
},

borrar_post(pid){


    axios.post(window.hostname+'api/superadmin/borrar_post',
    {id:pid}
    )
    .then(response =>     {

      this.initialize_()

    })

    .catch(e=>{

        console.log("Error al cargar...", e)
    })
},


  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    this.dt_dia = sevenDaysAgo.toISOString().split('T')[0]
    
  },
  watch:{
    dt_dia(val){

      this.initialize_();

    },
  }

}
</script>


<style scoped>
 .tablex {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
    }

    .tablex th, .tablex td {
        text-align: left;
        padding: 8px;
        border-bottom: 1px solid #ddd;
 
    }

    .tablex th {
      background-color: rgb(38, 53, 67);
        color: white;
    }

    .tablex tr:nth-child(even) {
        background-color: #f2f2f2;
    }
td{
  text-align: left;
}
.articulo-principal{
position: relative;


}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

