<template>
  <div id="app">
    <notifications group="foo" position="top center" />
    <notifications group="foos" position="top right" />
    <!--
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    -->
    <router-view v-if="!loading"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import axios from 'axios';

export default {
    data() {
      return {
        loading:true,
        
      }
    },
      computed: {
    ...mapGetters(['admin_datos', 'isLoggedIn', 'regiones', 'comunas']),
    isInitialized(){
      if (this.regiones.length > 0 &&  this.comunas.length > 0)
        return true;
      return false;
    }
    
    },
    methods: {
    ...mapActions(['getpro', 'initialize', 'logoutAdmin']),
    },
    created(){
      this.initialize();
      
      if(this.admin_datos.length == 0 && typeof(localStorage.token_auth) != "undefined") //Relogear si recarga la pagina
      {
        
        this.cargando = true;
        axios.defaults.headers.common['Authorization'] = localStorage.token_auth
        this.getpro().then(e=>{this.loading = false; this.$router.push('/Home') }).catch(e=>{this.loading = false;});
        
      }
      else{
        this.$router.push('/')
        this.loading = false;
      }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
.loader-cnt{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}
.btn-std{
  background:#FF8C00 !important;
  color:white !important;
  border: none;
}
.btn-std:hover{
  background:#ffac46 !important;
  color:white !important;
}
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.vue-notification-group{
  margin-top:80px !important;
}

.btn-estandar{
  background-color: rgb(39, 170, 226);
}

.btn-std-2 {
  display: block;

  padding: 10px;
  background-color: #008CBA;
color: white;
border: none;
border-radius: 4px;
font-size: 14px;
cursor: pointer;
text-align: center;

}
</style>
