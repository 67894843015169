<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  <div class="column-right">
    <div class="main-container">
        <div class="">
    <div v-if="loaded">


  <br />
    <h4 style="text-align:left; margin-bottom:20px;">Horarios <b-icon icon="clock"/></h4>
<!--
<button v-on:click="reasignar_horario()" style="margin-bottom:10px;" class="btn btn-dark">Reasignar ordenes</button>
-->
  <table style="width:100%; font-size:14px;">

    <tr style="background-color:rgba(0,0,0,0.8); color:white;">

      <td>Días</td>
      <td> Horario</td>
      <td> Vigencia</td>

      <td> Comunas</td>      
      <td> Categorias</td>

      <!--<td>Anticipacion</td>
-->
      <td></td>
    </tr>
    <tr style="text-align:left;" v-for="h in profesional.horarios">

      <td>
       <span v-if="r==1" v-for="r, i in h.repeticion">{{dias[i]}}, </span> 
    </td>
     <td>
       {{timeConvert(h.inicio)}} - {{timeConvert(h.termino)}}
     </td>
        <td>
          <div v-if="h.vigencia_desde != null && h.vigencia_hasta != null">
          {{new Date(h.vigencia_desde.a, h.vigencia_desde.m-1, h.vigencia_desde.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}
          -
          {{new Date(h.vigencia_hasta.a, h.vigencia_hasta.m-1, h.vigencia_hasta.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}
          </div>
          <div v-else>
            Indefinido
          </div>
        </td>
        
       <td>   
        <div v-for="c in h.comunas">{{comunas.filter(x=>{return x.id == c})[0].Nombre}}</div>
      </td>
       <td>   
        <div v-for="c in h.categorias">{{categorias.filter(x=>{return x.id == c})[0].nombre}}</div>
      </td>


      <!--<td>{{timeConvert2(h.anticipacion)}}</td>-->

      <td>
        <b-icon style="color:red; cursor:pointer;" v-on:click="eliminar_horario(h.id)" icon="trash" />
        <router-link :to="'/ModificarHorario/'+h.id" ><b-icon style="color:blue; cursor:pointer;"  icon="pen" /></router-link>
      </td>
    </tr>
  </table>
  <br />

<!--
<div class="calendario">
                  <table style="width:100%;">
                    <td>
                    <button style="margin-right:6px;" class="btn btn-info" v-on:click="semanaAnterior();">  <b-icon icon="arrow-left-circle" style="margin-right:6px;" /> Semana Anterior </button>
                   </td>
                   <td style="text-align:center;"> <h2> Semana del {{desdeD.getDate()}} de {{meses[desdeD.getMonth()]}} del {{desdeD.getFullYear()}}</h2></td>
                   <td style="text-align:right;">
                    <button class="btn btn-info" v-on:click="semanaSiguiente()">  Semana Siguiente <b-icon icon="arrow-right-circle" style="margin-left:6px;" /></button>
                  </td>

                  </table>
            <div  style="padding:0px; margin-bottom:40px; position:relative; margin-top:0px; text-align:left;" class="shadow-object">
              <div v-if="!loaded_calendario" style="position:absolute; height: 100%; width:100%; background:rgba(0,0,0,0.4); z-index:999;">
                  <div style="position:absolute; top:50%; color:white; left:50%;">
                    <div class="spinner-border spinner-border-sm"  role="status"></div>
                    Cargando...
                      
                  </div>
              </div>

                    <table class="Table-Normal" style="width:100%; text-align:center;">
                      <td style="min-width:12.5%; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;">Horario</td>
                      <td style="width:12.5%; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;" v-for="d, i in (window.width >= 800 ? dias2 : dias)">{{d}} {{viendoSemana[i]}}</td>
                    
                    </table>
                    <table style="width:12.5%; text-align:center;     margin-right: auto;  margin-left: 0px;">
               
                      <tr v-for="i in Math.ceil((horaMax-horaMin)/60)" style="text-align:center; font-size:10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height:50px; height:50px; line-height:48px; background-color:rgb(38,53,66); border:1px solid white; color:white;">
                        {{timeConvert(horaMin+(i-1)*60)}}-{{timeConvert(horaMin+(i)*60)}}

                      </tr>
                    </table>

                      <div v-for="h in calendario.ordenes" :key="h.id"   v-if="new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) >= desdeD && new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) <= hastaD" 
                      :style="{
                      'left':(12.5*(viendoSemana.findIndex(x=>{return x == h.fecha.d})+1)).toString()+'%', 'line-height':(50*(h.hasta-h.desde)/60)+'px', 
                      'height':(50*(h.hasta-h.desde)/60)+'px', 'top':(((h.desde-horaMin)*50/60)+25).toString()+'px',
                      'background': h.estado == 2 ? 'repeating-linear-gradient(45deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px)': h.estado == 1 ? 'repeating-linear-gradient(45deg,#FF851B,#FF851B 10px,#fcb577 10px,#fcb577 20px)' : 'repeating-linear-gradient(45deg,#3D9970,#3D9970 10px,#549c7c 10px,#549c7c 20px)'
                      }" 
                      style="font-size:12px; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%;">
                        Id: {{h.id}}
                    </div>
            </div>                     
</div>
-->

<br />
<hr />
<h4 style="margin-bottom:20px; text-align:left;">Agregar un nuevo horario <b-icon icon="plus"/></h4>



         



 <h4 style="font-size:16px;"> <span class="numerito">1</span> Horas, dias y vigencia</h4>
                      <table style="margin: 0; margin-bottom:20px">
  
                        <td style="vertical-align:middle; padding-right:20px;">
                          <tr>
                          Inicio:
                            </tr>
                            <tr>
                          <select v-model="agregando_horario.inicio">
                            <option v-for="v in Math.round(1440/15)"  :value="(v-1)*15">{{timeConvert((v-1)*15)}}</option>
                          </select>
                          </tr>
                        </td>
                        <td style="vertical-align:middle; padding-right:20px;">
                          <tr>
                          Termino: 
                          </tr>
                          <select v-model="agregando_horario.termino">
                            <option  v-for="v in Math.round((1440-agregando_horario.inicio)/periodo)" :value="(v)*periodo + agregando_horario.inicio">{{timeConvert((v)*periodo + agregando_horario.inicio)}}</option>
                          </select>
                        </td>
                      
                      <td style="vertical-align:middle;">
                        <table>
                          <tr>
                            <td style="text-align:center; width:30px;" v-for="x in dias">
                              {{x}}
                            </td>
                          </tr>
                          <tr>
                          <td  style="text-align:center;" v-for="x in agregando_horario.repeticion">
                              <input   type="checkbox" v-model="x.value" :true-value="1" :false-value="0">
                          </td>
                          </tr>
                        </table>
                      </td>
                      </table>
                      Vigencia: 
                      <div v-on:click="indefinido = 1" :class="{'vig-select': indefinido == 1}" class="vig">Indefinido</div>
                      <div v-on:click="indefinido = 0" :class="{'vig-select': indefinido != 1}" class="vig"> Plazo fijo</div>
                      
                      <div>
                      <div :style="{'opacity': indefinido == 1 ? '0.2': '1'}" style="margin-top:10px; padding:10px 0px;  position:relative; display:inline-block;">
                        <div v-if="indefinido == 1" style=" position:absolute; height:100%; width:100%; cursor: not-allowed;"> </div>
                         <div>Plazo:</div>
                          Desde <input id="x2" type="date" v-model="dt_dia" > 
                          Hasta: <input id="x2" type="date" v-model="dt_dia2" >
                        </div>
                      </div>

              <div  class="d-comunas">
                 <h4 style="font-size:16px;"> <span class="numerito">2</span> Localización</h4>
                  Comunas 
                  <span style="color:#FF8C00;">
                  {{comunas_selected.length > 0 ? comunas_selected.length > 1 ? '('+comunas_selected.length+' agregadas)' : '('+comunas_selected.length+' agregada)' : ''}}
                </span>
                <div style="display:inline-block; margin-bottom:8px;">

                  <div style="border: 1px solid rgba(0,0,0,0.7); position:relative; padding:1px; border-radius:5px; ">
                    <b-icon style="position:absolute;right:5px; top:5px;" icon="search"></b-icon>
                  <input placeholder="Escribe para buscar..." style=" outline:none; border:none;" type="text" v-model="comunas_buscadas">
                </div>

                  <div style="margin:0; position:relative; text-align:center; width:200px;">
                      <div v-if="comunas_buscadas != ''" style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;">
                      <div v-on:click="agregar_comunas(r.item.id)" style="cursor:pointer; text-align:left; padding:2px 5px;" v-for="r in resultado_fuse_comuna.slice(0,10)">
                        <b-icon style="margin-right:4px;" icon="geo-alt"></b-icon>
                          <span >{{r.item.Nombre}}<b-icon icon="check" v-if="comunas_selected.includes(r.item.id)"/> </span>
                      </div>
                      </div>
                  </div>
                </div>
                  <div style="font-weight:700;" v-if="comunas_selected.length > 0">
                  <div v-on:click="agregar_comunas(c)" style="cursor:pointer; border: 2px solid rgba(0,0,0,0.6); border-radius:10px; padding: 2px 5px; display:inline-block; margin-right:4px;" v-for="c in comunas_selected">
                    {{comunas.filter(x=>{return x.id == c})[0].Nombre}} <b-icon icon="x-circle"></b-icon>
                  </div>
                  
                  </div>  
                          
              </div>

                             <div class="d-categorias">
           <h4 style="font-size:16px; margin-top:15px;"> <span class="numerito">3</span> Servicios a atender</h4>

  
                      <table style="min-width: 300px; margin: 0px 0px; ">
                        <tr style="background:rgba(0,0,0,0.8); color:white;">

                          <td>Servicios</td>
                          <td>¿Habilitado en este horario?</td>
                        </tr>
                        <tr v-for="c in editando_categorias">
                          
                          <td  style="padding:5px;">{{c.nombre}}</td>
                          <td style="text-align:center; padding:5px;"><input type="checkbox" v-model="c.puede"> </td>

                        </tr>
                      </table>
               </div>
           <h4 style="font-size:16px; margin-top:15px;"> <span class="numerito">4</span> Otras opciones</h4>

  
            <div style="margin-bottom:20px; margin-top:15px;">
              Anticipación necesaria (en horas):
              <select v-model="anticipacion">
                <option v-for="v in 25" :value="(v-1)*60">{{timeConvert((v-1)*60)}}</option>
              </select>
            </div>    
                                <div style="margin-bottom:20px">
                                  Anticipación mínima (en horas):
          <select v-model="anticipacion_minima">
            <option v-for="v in 25" :value="(v-1)*60">{{timeConvert((v-1)*60)}}</option>
          </select>
               </div>
                           <div style="margin-bottom:20px">
                                  Anticipación autoconfirmación (en horas): 
          <select v-model="anticipacion_autoconfirmacion">
            <option v-for="v in 25" :value="v-1">{{timeConvert(((v-1)*60))}}</option>
          </select>
               </div>        



                       <div style="text-align:center;">
                        <button class="btn btn-std" style="margin: 10px auto; " v-on:click="agregar_horario(agregando_horario.inicio, agregando_horario.termino, agregando_horario.repeticion)">
                          <b-icon icon="plus-circle" style="margin-right:6px;" />Agregar horario
                        </button>
           </div>

               </div>  



           </div>
</div>






  </div>
  </div>


</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import InputColorPicker from 'vue-native-color-picker'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    InputColorPicker,
  },
  data() {
    return {
      profesional:{},
      agregando_horario:{inicio:-1, termino:-1, repeticion:[{value:0},{value:0},{value:0},{value:0},{value:0},{value:0},{value:0}]},
      periodo:60,
      loaded:false,
      comunas_selected:[],
      resultado_fuse_comuna:[],
      comunas_buscadas:'',
      categorias:[],
      editando_categorias:[],
      anticipacion:1440,
      color:'#000000',
      anticipacion_minima:0,
      anticipacion_autoconfirmacion:14,


      loaded_calendario:false,
      calendario:{ordenes:[], bloqueos:[]},
      hastaD:0,
      desdeD:0,
      mapping:[6, 0, 1, 2, 3, 4, 5],
      meses:['Enero', 'Febrero', 'Marzo', 'Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Nobiembre','Diciembre'],
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Do'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],

      dt_dia:'',
      dt_dia2:'',

      indefinido:1,

     window: {
        width: 0,
        height: 0
      },
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  
    this.initialize_(this.$route.params.id);

    this.hoy = new Date();
    this.hoyMes = this.hoy.getMonth();
    this.hoyDia = this.hoy.getDate();  
    this.hoyAño = this.hoy.getFullYear();
    this.desdeD = new Date()
    this.desdeD.setDate(this.desdeD.getDate() - this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))
    this.hastaD = new Date()
    this.hastaD.setDate(this.hastaD.getDate() + 6-this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))

    this.viendoDia = this.hoyDia;
    this.viendoMes = this.hoyMes;
    this.viendoAño = this.hoyAño
    this.ver_calendario()
  },

  methods:{
    ...mapActions(['initialize']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    semanaSiguiente(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()+7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()+7)   
      this.hastaD = H
      this.ver_calendario();
    },
    semanaAnterior(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()-7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()-7)   
      this.hastaD = H
      this.ver_calendario();
    },
    getDia(año, mes, dia){
      return this.mapping[new Date(año, mes, dia).getDay()];

    },
    reasignar_horario(){
  
      axios.post(window.hostname+'api/admin/reasignar_ordenes', 
      {profesional_id: this.$route.params.id})
      .then(response => 
      {            
          this.initialize_(this.$route.params.id);
          this.initialize()
      })

      .catch(e=>{
        console.log("Error al cargar...")
      })
    },
    agregar_comunas(cid){
        this.comunas_buscadas = ''
        if(!this.comunas_selected.includes(cid))
          this.comunas_selected.push(cid)
        else
          this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1)

    },
   timeConvert2 (n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  + rhours + " h " + rminutes + " m.";
    },


    eliminar_horario(hid){
      axios.post(window.hostname+'api/admin/eliminar_horario', 
        {horario_id: hid})
        .then(response => 
        {
            
            this.initialize_(this.$route.params.id);
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },

    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    
      initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.post(window.hostname+'api/profesional/init_modificar_pro', 
        {id: pid})
        .then(response => 
        {
            this.loaded = true;
            this.profesional = response.data.profesional
            this.categorias = response.data.categorias
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })
      }
    },
    ver_calendario()
    {
      if (typeof(this.$route.params.id === 'number'))
          {
            this.loaded_calendario = false;
            axios.post(window.hostname+'api/profesional/ver_calendario_pro', 
            {profesional_id: this.$route.params.id,
            dia:this.desdeD.getDate(),
            mes:this.desdeD.getMonth()+1,
            año:this.desdeD.getFullYear(),
            dia2:this.hastaD.getDate(),
            mes2:this.hastaD.getMonth()+1,
            año2:this.hastaD.getFullYear(),
            })
            .then(response => 
            {
                this.loaded_calendario = true;
                this.calendario.ordenes = response.data.ordenes
                //this.categorias = response.data.categorias
            })

            .catch(e=>{
                this.loaded = true;
                console.log("Error al cargar...")
            })
          }
        },

        agregar_horario(inicio, termino, repeticion){

      var error_msg = ''
      if(inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < repeticion.length; i++)
        x += repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '
console.log("AAAAAAAA", x)
      let cat = []
      for(var j = 0; j < this.editando_categorias.length; j++){
        if (this.editando_categorias[j].puede){
          cat.push(this.editando_categorias[j].id)
        }

      }
      if(cat.length == 0)
        error_msg = 'Debes agregar al menos una categoría'
      if(this.comunas_selected.length == 0)
        error_msg = 'Debes agregar al menos una comuna'

      if(error_msg == ''){

        axios.post(window.hostname+'api/profesional/agregar_horario', 
        {
          inicio: inicio,
          termino: termino,
          repeticion:x,
          categorias:cat,
          comunas:this.comunas_selected,
          anticipacion:this.anticipacion,
          anticipacion_minima:this.anticipacion_minima,
          anticipacion_autoconfirmacion:this.anticipacion_autoconfirmacion,
          vigencia_desde: this.dt_dia,
          vigencia_hasta: this.dt_dia2,
          tipo:this.indefinido == 1 ? 1 : 2,
          } )
        .then(resp=>{

          this.$notify({duration:5000, group: 'foos', title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{
          this.$notify({duration:5000, group: 'foos', title: 'Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        console.log("LOL", error_msg)
        this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: error_msg, type: 'error'})
      }

    },

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),

    viendoSemana: function(){
      var o;
      var f = [new Date(this.viendoAño, this.viendoMes-1, this.desdeD.getDate())];
      var ret = [this.desdeD.getDate()]
      for (var i = 1; i < 7; i++)
      {
        o = new Date(f[i-1].getFullYear(), f[i-1].getMonth(), f[i-1].getDate())
        o.setDate(o.getDate() + 1)
        f.push(o)
        ret.push(o.getDate())
      }

      return ret;
     
    },
    horas: function(){
      let h = []
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {

          h.push(this.profesional.horarios[i].ordenes[j])
      
        }

      }
      return h;

    },
    horaMin: function(){
      var min = 360;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde < min)
          {
            min = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return min
    },

    horaMax: function(){
      var max = 1080;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde > max)
          {
            max = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return max
    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    profesional(){
      this.editando_categorias = []
      for(var i = 0; i < this.categorias.length; i++){
        this.editando_categorias.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, puede:this.profesional.categorias.some(x=>{return x == this.categorias[i].id})})
      }
    }
  }

}
</script>
<style scoped >
.d-comunas{
  margin-top:15px;
}

 .vig{
   display: inline-block;
   border: 2px solid #FF8C00;
   padding: 4px 10px;
   margin-right:5px;
   border-radius: 5px;
   color:#FF8C00;
   cursor: pointer;
 }
 .vig-select{
   background: #FF8C00;
   color:white;
 }

 .numerito{
   background: rgba(0,0,0,0.8); color:white; font-size:13px; font-weight:700; border-radius:100px; padding: 1px 6px;
 }
 .column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.column-left{

  height:100vh;
  float: left;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}

td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>