<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">     
      <h2 style="margin-bottom: 30px;">Agregar un bloque de horario</h2>  
      <div v-if="loaded" class="articulo-principal">
        Desde <input id="x2" type="date" v-model="dt_dia" > 
                          Hasta: <input id="x2" type="date" v-model="dt_dia2" >

                          <table style="margin: 0; margin-bottom:20px">
  
  <td style="vertical-align:middle; padding-right:20px;">
    <tr>
    Inicio:
      </tr>
      <tr>
    <select v-model="inicio">
      <option v-for="v in Math.round(1440/15)"  :value="(v-1)*15">{{timeConvert((v-1)*15)}}</option>
    </select>
    </tr>
  </td>
  <td style="vertical-align:middle; padding-right:20px;">
    <tr>
    Termino: 
    </tr>
    <select v-model="termino">
      <option  v-for="v in Math.round((1440-inicio)/periodo)" :value="(v)*periodo + inicio">{{timeConvert((v)*periodo + inicio)}}</option>
    </select>
  </td>
                          </table>
                          <button v-on:click="agregar_bloqueo()" class="btn btn-std">Agregar Bloqueo</button>   
        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:true,
      dt_dia:'',
      inicio:-1,
      termino:-1,
      dt_dia2:'',
      periodo:60,

    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    agregar_bloqueo(){

var error_msg = ''

if(this.inicio == -1)
  error_msg += 'Por favor, ingrese una hora de inicio. '
if(this.termino == -1)
  error_msg += 'Por favor, ingrese una hora de término. '

if(this.inicio > this.termino)
  error_msg += 'La hora de inicio no puede ser mayor a la de término. ' 
  
if(this.dt_dia == '' || this.dt_dia2 == '')
   error_msg = 'Debes ingresar un intervalo de dias.'
if(this.dt_dia > this.dt_dia2)
   error_msg = 'El dia desde no puede ser mayor al dia hasta.'

var x = '';



if(error_msg == ''){
  
  axios.post(window.hostname+'api/admin/agregar_bloqueos', 
  {profesional_id: this.$route.params.id,
    inicio: this.inicio,
    termino: this.termino,

    vigencia_desde:this.dt_dia,
    vigencia_hasta:this.dt_dia2,


    } )
  .then(resp=>{

    this.$notify({duration:5000, group: 'foos', title: 'Bloqueo agregado', text: '¡Se ha agregado el bloqeuo!', type: 'success'})
    this.initialize_(this.$route.params.id);
  })
  .catch(e=>{ 
    this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
  })
}
else{
  console.log("LOL", error_msg)
  this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: error_msg, type: 'error'})
}

},


  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

