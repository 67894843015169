<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">       
      <div v-if="loaded" class="articulo-principal">

          <!--
        <div v-for="p in profesionales">
            <div>
              {{(p.profesional.nombre + ' ' + p.profesional.apellido)}}
            </div>
            <div v-for="h in p.horarios">
              {{(timeConvert(h.inicio) +' - '+timeConvert(h.termino))}} en <span v-for="c in h.comunas">{{c.nombre}}  </span>
            </div>

          </div>
          -->
          <div class="sectionv">

            <h2>Datos personales 📋</h2>
          
          <div style="" class="input-cont">
                  <input v-model="nombre_" @focus="focused_nombre_ = true;" @blur="focused_nombre_ = false;" type="text">
                  <div :class="{'subido':focused_nombre_ || nombre_ != ''}" class="input-label">Nombre</div>
                  </div>

                  <div style="" class="input-cont">
                  <input v-model="apellido_" @focus="focused_apellido_ = true;" @blur="focused_apellido_ = false;" type="text">
                  <div :class="{'subido':focused_apellido_ || apellido_ != ''}" class="input-label">Apellido</div>
                  </div>

                  <div style="" class="input-cont">
                  <input v-model="email_" @focus="focused_email_ = true;" @blur="focused_email_ = false;" type="text">
                  <div :class="{'subido':focused_email_ || email_ != ''}" class="input-label">Correo electrónico</div>
                  </div>



                  <div style="" class="input-cont">

                  <div class="num_prefijo" v-on:click="seleccionando_telefono_ = !seleccionando_telefono_" style="position:relative; float:left; cursor:pointer; display:inline-block; border-bottom:1px solid rgba(0,0,0,0.4);  padding:0px 4px 8px 8px; vertical-align:top;">
                  
                    <img class="num_prefijo" style="height:16px; vertical-align:middle; margin-top:4px;" :src="getFlagUrl(banderas.filter(z=>{return tel_prefijo == z.num})[0].arch)" alt="">
                    <div class="num_prefijo" style="display:inline-block; vertical-align:middle; padding-left:7px; padding-right:15px; transform:translateY(3px);">
                      {{tel_prefijo}}
                    </div>
                    
                    <b-icon class="num_prefijo" style="position:absolute; top:12px; right:4px; cursor:pointer; height:12px; width:12px;" icon="caret-down"></b-icon>
                  </div>


                  <div style="position:relative; display:inline-block; width:Calc(100% - 91px);">
                    <input v-model="telefono_" @focus="focused_telefono_ = true;" @blur="focused_telefono_ = false;" type="number">
                    <div :class="{'subido2':focused_telefono_ || telefono_ != ''}" class="input-label">Teléfono</div>
                  </div>
                  
                  <div class="num_prefijo" v-if="seleccionando_telefono_" style="position:absolute; overflow-y: scroll; max-height:200px; top:32px; background:white; border-radius:5px; border: 1px solid rgb(210,210,210); padding:5px 0px; z-index:10;">
                    <div class="num_prefijo" v-for="b in banderas" v-on:click="tel_prefijo = b.num; seleccionando_telefono_ = false;"  style="padding:6px 10px; cursor:pointer; ">
                      <img style="height:20px; vertical-align:middle; margin-top:2px;" :src="getFlagUrl(b.arch)" alt="">
                      <div style="display:inline-block; vertical-align:middle;">
                        <div style="display:inline-block; padding:0px 5px 0px 5px;">
                          {{b.num}}
                        </div>
                        {{b.nom}} 
                      </div>                    
                    </div>                
                  </div>
                  
                  </div>

                  <div style="" class="input-cont">
                  <input v-model="direccion_" @focus="focused_direccion_ = true;" @blur="focused_direccion_ = false;" type="text">
                  <div :class="{'subido':focused_direccion_ || direccion_ != ''}" class="input-label">Dirección</div>
                  </div>

                  Comuna: <div style="display:inline-block; margin-bottom:8px;">

<div style="border: 1px solid rgba(0,0,0,0.7); position:relative; padding:1px; border-radius:5px; ">
  <b-icon style="position:absolute;right:5px; top:5px;" icon="search"></b-icon>
<input :placeholder="comuna_selected == 0 ?  'Escribe para buscar...' : comunas.find(z=>{return z.id == comuna_selected}).Nombre" style=" outline:none; border:none;" type="text" v-model="comunas_buscadas">
</div>

<div style="margin:0; position:relative; text-align:center; width:200px;">
    <div v-if="comunas_buscadas != ''" style="background:white; z-index: 9999; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;">
    <div v-on:click="comuna_selected = r.item.id; comunas_buscadas = '';" style="cursor:pointer; background:white; text-align:left; padding:2px 5px;" v-for="r in resultado_fuse_comuna.slice(0,10)">
      <b-icon style="margin-right:4px;" icon="geo-alt"></b-icon>
        <span >{{r.item.Nombre}}<b-icon icon="check" v-if="comuna_selected == r.item.id"/> </span>
    </div>
    </div>
</div>
</div>
<br />
<br />

                  <div style="" class="input-cont">

                    <div :class="{'btn-casa-depto-s': departamento_ == 0}" @click="departamento_ = 0" class="btn-casa-depto">Casa</div>
                    <div :class="{'btn-casa-depto-s': departamento_ == 1}" @click="departamento_ = 1" class="btn-casa-depto">Departamento</div>

                  </div>

                  <div style="" class="input-cont">
                  <input v-model="n_casa_depto" @focus="focused_n_casa_depto = true;" @blur="focused_n_casa_depto = false;" type="text">
                  <div :class="{'subido':focused_n_casa_depto || n_casa_depto != ''}" class="input-label">Número de casa / depto</div>
                  </div>


          </div>
                  <div class="sectionv">
                    <h2>Información chat 💬 </h2>
                    <div style="transform: translateY(-25px);">(nueva solicitud) </div>
                    <div style="" class="input-cont">
                  <input v-model="titulo_" @focus="focused_titulo_ = true;" @blur="focused_titulo_ = false;" type="text">
                  <div :class="{'subido':focused_titulo_ || titulo_ != ''}" class="input-label">Título</div>
                  </div>
                    <div style="" class="input-cont">
                  <input v-model="descripcion_" @focus="focused_descripcion_ = true;" @blur="focused_descripcion_ = false;" type="text">
                  <div :class="{'subido':focused_descripcion_ || descripcion_ != ''}" class="input-label">Descripción</div>
                  </div>

                  </div>

                  <div  class="sectionv" style="text-align: left">
                    


                   <h2> Servicio 🔨</h2>
                    
                    <table style="margin-left:0px;">
                      <tr>
                        <td>Nombre</td>
                        <td><input v-model="servicio_nombre" type="text"></td>  
                      </tr>
                      <tr>
                        <td>descripcion</td>
                        <td><input v-model="servicio_descripcion" type="text"></td>  
                      </tr>
                      <tr>
                        <td>Precio</td>
                        <td><input v-model="servicio_precio" type="number"></td>  
                      </tr>
                      <tr>
                        <td>¿Es presupuesto?</td>
                        <td><input type="checkbox" v-model="servicio_presupuesto" /></td>  
                      </tr>
                    </table>

                  
                  <br />
                    <button v-on:click="agregar_servicio()" class="btn btn-info">Agregar servicio</button>
                    <div>
                    </div>
                    <br />
                    <div style="border: 1px solid rgba(0,0,0,0.2); border-radius: 4px; padding: 10px; display: inline-block;" v-if="servicios_agregados.length > 0">
                      
                     <span style="font-weight: 700;"> Servicios agregados:</span>
                     <br/>
                      <div v-for="s, i in servicios_agregados">
                        <span style="padding: 0px 4px; color:green; font-size: 14px; border: 1px solid green; border-radius: 4px;" v-if="s.es_presupuesto == 1">Presupuesto</span>
                        
                        {{ s.nombre }}: ${{Intl.NumberFormat('de-DE').format( s.precio )}} 
                        <b-icon v-on:click="servicios_agregados.splice(i, 1)" style="cursor: pointer; color:#FF4136" icon="x-circle"></b-icon> 
                      </div>
                      <br />
                      <span style="font-weight: 700;">
                        Total orden: ${{ Intl.NumberFormat('de-DE').format(servicios_agregados.reduce((x, y)=>{return parseInt(x) + parseInt(y.precio)}, 0) )}}
                      </span>  
                    </div>
                    <br />
                  </div>
                  

                 <div class="sectionv" style="margin-top: 2px; ">
                  <h2>Fecha y hora 📅</h2>
                 Fecha: <input id="x2" type="date" v-model="dt_dia" >
                  <br/>
                  <br/>
                        Inicio:
                        <select v-model="tiSelected">
      <option v-for="v in Math.round(1440/15)"  :value="(v-1)*15">{{timeConvert((v-1)*15)}}</option>
    </select>
    <div style="display: inline-block; margin-left:20px;">
      Término: 
      <select v-model="tfSelected">
      <option  v-for="v in Math.round((1440-tiSelected)/periodo)" :value="(v)*periodo + tiSelected">{{timeConvert((v)*periodo + tiSelected)}}</option>
    </select>
    </div>
                      </div>


<div class="sectionv">
  <h2>Profesional 👨‍💼</h2>
            <div style="text-align: left;">
          <table style="margin-top:20px; margin-left: 0px;">
                  <tr>
                    <td style="width:20px;"></td>
                  <td>Nombre</td>
                  <td>Estado</td>  
                  </tr>
                  <tr v-for="p in profesionales">
                    <td><input type="radio" :value="p.id" v-model="profesional_selected" name="radio"></input></td>
                    <td >{{(p.nombre + ' '+p.apellido)}}</td>
                    <td style="width:150px;" :style="{'color':p.disp == 'disponible' ? '#2ECC40' : p.disp == 'sin horario' ? '#FFDC00' : p.disp == 'bloqueado' || p.disp == 'ocupado' ? '#FF4136' : ''}"> 
                      <div v-if="(p.disp != 'cargando...')">{{p.disp}} </div>
                      <div v-else>
                        <div  style="color:rgba(0,0,0,0.8); display: inline-block; font-size: 14px;">Cargando...</div>
                      <div class="spinner-border spinner-border spinner-border-sm"
                      style="
                        margin-left: 10px;
                        margin-right: 14px;
                        margin-top: 2px;
                        margin-bottom: 2px;
                        color: grey;
                      "></div>
                      </div>
                    </td>

                  </tr>
                </table>
              </div>
</div>     

              <button v-on:click="enviar()" class="btn btn-primary">Agendar</button>
                
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:false,
      profesionales:[],

      nombre_:'',
    apellido_:'',
    email_:'',
    descripcion_:'',
    titulo_:'',
    telefono_:'',
    direccion_:'',
    tel_prefijo:'+56',
    departamento_:-1,
    n_casa_depto:'',

    focused_nombre_:false,
    focused_titulo_:false,
    focused_descripcion_:false,
    focused_apellido_:false,
    focused_email_:false,
    focused_telefono_:false,
    focused_direccion_:false,
    focused_n_casa_depto:false,
    seleccionando_telefono_:false,
    puedeContinuar:true,

    dt_dia:'',

    tiSelected:-1,
    tfSelected:-1,
    periodo:30,
    profesional_selected:0,

    servicios_agregados:[],
    servicio_nombre:'',
    servicio_descripcion:'',
    servicio_precio:0,
    servicio_presupuesto:1,

    comunas_buscadas:'',
    resultado_fuse_comuna:[],

    comuna_selected:0,

    banderas:[
        {arch:'cl.svg', num:'+56', nom:'Chile'},
        {arch:'ar.svg', num:'+54', nom:'Argentina'},
        {arch:'br.svg', num:'+55', nom:'Brasil'},
        {arch:'us.svg', num:'+1', nom:'Estados Unidos'},
        {arch:'co.svg', num:'+57', nom:'Colombia'},        
        {arch:'mx.svg', num:'+52', nom:'Mexico'},
        {arch:'pe.svg', num:'+51', nom:'Peru'},
        {arch:'uy.svg', num:'+598', nom:'Uruguay'},
        {arch:'bo.svg', num:'+591', nom:'Bolivia'},
    ],


    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
  },

  methods:{
    ...mapActions(['initialize']),
    agregar_servicio(){
      this.servicios_agregados.push({'nombre':this.servicio_nombre, 'descripcion':this.servicio_descripcion, 'precio':this.servicio_precio, 'es_presupuesto':this.servicio_presupuesto})
    },
    enviar(){
        if (this.puedeContinuar){
          let fecha_ = this.dt_dia.split("-")
          axios.post(window.hostname+'api/superadmin/nueva_orden_manual',
          {
          pro_id:this.profesional_selected,          
          d: parseInt(fecha_[2]),
          m: parseInt(fecha_[1]),
          a: parseInt(fecha_[0]),

          desde:this.tiSelected,
          hasta:this.tfSelected,

          comuna_id: this.comuna_selected,

          servicios:this.servicios_agregados,

          nombre:this.nombre_,
          apellidos:this.apellido_,
          email:this.email_,
          telefono:this.tel_prefijo+this.telefono_,
          direccion:this.direccion_,
          departamento:this.departamento_,
          n_depto:this.n_casa_depto,
          titulo:this.titulo_,
          descripcion:this.descripcion_,

          })
          .then(resp => 
          {
            this.initialize_()
            this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: '¡Se ha agendado exitosamente!',
            type:'success'
            });
            
          })
          .catch(e=>{  
            this.$notify({
            group: 'foo',
            title: '¡Error!',
            text: '¡Ha ocurrido un error!',
            type:'error'
            });        
          })
        }
        else{
        }
      },
    checkearProfesionales(){
       if (this.tiSelected == -1 || this.tfSelected == -1 || this.selectedAño == -1 || this.seleccionarDia == -1 || this.selectedMes == -1)
        {
          return;
        }
        function f(i,t){
          let fecha_ = t.dt_dia.split("-")
          console.log(fecha_[1])
          axios.post(window.hostname+'api/superadmin/profesional_puede_atender',
          {
            pro_id: t.profesionales[i].id,
            inicio: t.tiSelected,
            termino:t.tfSelected,
            d: parseInt(fecha_[2]),
            m: parseInt(fecha_[1]),
            a: parseInt(fecha_[0]),
          })
          .then(response => {
            console.log(t.profesionales[i], i)
            t.profesionales[i].disp = response.data.estado
          })
          .catch(e=>{
            console.log(e)
          })
        }
        for(var i = 0; i <  this.profesionales.length; i++){
          this.profesionales[i].disp = 'cargando...'
            f(i, this);
        }
      },
    getFlagUrl(pic) {
    return require('../../assets/banderas/'+pic)
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.get(window.hostname+'/api/superadmin/init_orden_manual',)
        .then(response => 
        {
            this.loaded = true;
            this.profesionales = response.data.profesionales;
   
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
      }
    },
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  },
  watch:{
    comunas_buscadas(val){
      console.log("oaisdj")
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    tiSelected(val){
          if (val != -1 && this.tfSelected != -1 && this.selectedAño != -1 && this.seleccionarDia != -1 && this.selectedMes != -1)
           this.checkearProfesionales()
        },
      tfSelected(val){
        if (this.tiSelected != -1 && val != -1 && this.selectedAño != -1 && this.seleccionarDia != -1 && this.selectedMes != -1)
          this.checkearProfesionales()
      }
  }

}
</script>


<style scoped>
h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

.sectionv{
  background-color: rgb(250,250,250);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.input-cont{
  position:relative;
  text-align:left;
  max-width:400px;
  margin-bottom:40px;
}
.input-cont input{
  padding: 4px;

  border-radius: 4px;
  width:100%;
  outline:none;
  border-color: rgb(200,200,200);
}
.input-label{
  position:absolute;
  top:3px;
  left:6px;
  pointer-events: none;
  color:rgba(0,0,0,0.6);
  transition: 0.2s;
}
.subido{
  top:-16px;
  left:0px;
  font-size: 12px;
  color:#FF8C00;
}
.subido2{
  top:-16px;
  left:-84px;
  font-size: 12px;
  color:#FF8C00;
}
.btn-casa-depto{
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #FF8C00;
  margin-right:10px;
  color:#FF8C00;
  border-radius: 5px;
  cursor: pointer;
}
.btn-casa-depto:hover{
  color:white;
  background: #FF8C00;
}
.btn-casa-depto-s{
  color:white;
  background: #FF8C00;
}

td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

