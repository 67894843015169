<template>
<div style="text-align:left;">
  <ModalNuevoMail  @re-init="initialize_" ref="modalnuevomail" />
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">       
      <div v-if="loaded" class="articulo-principal">
          <h4>Campañas de mailing</h4>
          <br/>
          <button v-on:click="$refs.modalnuevomail.show()" style="margin-right: 10px;" class="crear-btn">Nueva campaña <b-icon icon="plus-circle"></b-icon></button>
          <button v-on:click="downloadFile()" class="crear-btn">Descargar HTML template <b-icon icon="plus-circle"></b-icon></button>
          <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Estado</th>
          <th>Asunto</th>
          <th>Nombre</th>
          <th>Fecha Creado</th>
          <th>Enviados</th>
          <th>Abiertos</th>
          <th>Cliqueados</th>
          <th>Desuscritos</th>
          <th>Tasa de Envio</th>
          <th>Acciones</th>
          <!-- Add more headers as needed -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="campaign in mails" :key="campaign.id">
          <td>{{ campaign.id }}</td>
          <td style="font-weight: 700;">
            <span style="color: #0074D9;" v-if="campaign.estado == 1">
              En proceso
            </span>
            <span style="color: #FFDC00;" v-if="campaign.estado == 0">
              Pausada
            </span>
            <span style="color: #3D9970;"  v-if="campaign.estado == 2">
              Terminada
            </span>
      

          </td>
          <td>{{ campaign.asunto }}</td>
          <td>{{ campaign.nombre }}</td>
          <td>{{ campaign.fecha_creado }}</td>
          <td><b>{{ campaign.enviados == null ? 0 : campaign.enviados }}</b></td>
          <td><b>{{ campaign.abiertos == null ? 0 : campaign.abiertos }}</b> <span style="font-style: italic;" v-if="campaign.enviados != 0">({{((campaign.abiertos/campaign.enviados)*100).toFixed(2) }}%)</span>  </td>
          <td><b>{{ campaign.cliqueados == null ? 0 : campaign.cliqueados }}</b><span style="font-style: italic;" v-if="campaign.enviados != 0">({{ ((campaign.cliqueados/campaign.enviados)*100).toFixed(2) }}%)</span></td>
          <td><b>{{ campaign.desuscrito == null ? 0 : campaign.desuscrito }}</b><span style="font-style: italic;" v-if="campaign.enviados != 0">({{((campaign.desuscrito/campaign.enviados)*100).toFixed(2) }}%)</span> </td>

          <td>{{ campaign.tasa_de_envio }}</td>
          <td>
            <b-icon v-if="campaign.estado == 0" v-on:click="cambiar_estado(campaign.id, campaign.estado)" icon="play-circle" class="icone" style="color:#3D9970" ></b-icon>
            <b-icon v-if="campaign.estado == 1" v-on:click="cambiar_estado(campaign.id, campaign.estado)" style="color: #FFDC00;" icon="pause-circle" class="icone"></b-icon>
            <b-icon icon="trash" v-on:click="eliminar(campaign.id)" style="color:#FF4136;" class="icone"></b-icon>
            <b-icon icon="pen" style="color:#0074D9;" class="icone"></b-icon>
          </td>  
          <!-- Add more cells as needed -->
        </tr>
      </tbody>
    </table>
        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalNuevoMail from '@/components/ModalNuevoMail.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    ModalNuevoMail,
  },
  data() {
    return {
      loaded:true,
      mails:[],


    }
  },
  created(){
    this.initialize_();
  
  },

  methods:{
    ...mapActions(['initialize']),

    async downloadFile() {
      try {
        const response = await axios.get(window.hostname+'api/superadmin/planilla_tipo', {
          responseType: 'blob', // Important to handle the file as a binary
        });

        // Create a URL for the blob and set it as the href attribute of a link element
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tempalte.html'); // Specify the filename

        // Append the link to the document body and trigger the click
        document.body.appendChild(link);
        link.click();

        // Clean up the link after the download
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    },
    initialize_(){

          this.loaded = false;
          axios.get(window.hostname+'api/superadmin/init_mailing')
          .then(response => 
          {
              this.loaded = true;
              this.mails = response.data.emails

          })
          .catch(e=>{
              this.loaded = true;
              console.log("Error al cargar...!")
          })
        
      },
    eliminar(eid){


      axios.post(window.hostname+'api/superadmin/eliminar_email_marketing',
      {email_id:eid})
          .then(response => 
          {
            this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: response.data.message,
          type:'success'
          });
              this.initialize_()

          })
          .catch(e=>{
            this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: 'No se ha podido eliminar.',
          type:'error'
          });  
              console.log("Error al cargar...!")
          })
        
      },
    cambiar_estado(eid, estado){


      if (estado == 2)
       return;

      axios.post(window.hostname+'api/superadmin/estado_email_marketing',
      {estado:estado == 1 ? 0 : 1, email_id:eid})
          .then(response => 
          {
            this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: response.data.message,
          type:'success'
          });
              this.initialize_()

          })
          .catch(e=>{
            this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: 'No se ha podido cambiar el estado',
          type:'error'
          });  
              console.log("Error al cargar...!")
          })
        
      },


  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    
  },
  watch:{

  }

}
</script>


<style scoped>
.crear-btn {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

  cursor: pointer;
  border-radius: 4px;
}

.icone{
  margin: 0px 3px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.icone:hover{
  opacity: 0.7;
}
table {
  margin: 10px 0px;
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}
td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

