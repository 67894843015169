
<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">   
      <div v-if="estado == 0">
        <div v-if="!loading" class="articulo-principal">
        <h2>Nuevo Post de blog</h2>
        Título:<br/>
         <input type="text" v-model="titulo"><br/><br/>
        Imagen portada:<br/>
         <input type="text" v-model="img_portada"><br/><br/>
        Abstract:<br/>
        <textarea  v-model="abstract" placeholder="Resumen que aparecerá para cliquear el post." id="w3review" name="w3review" rows="4" cols="50">
          
</textarea> <br/>
        <br/>
        Tags:

        <div v-on:click="addTag(c.id)" style="display: inline-block; cursor: pointer; margin: 0px 2px; border: 1px solid black; border-radius: 4px; padding: 0px 6px;" 
        :style="{
          'background-color': tags.includes(c.id) ? '#008CBA' : '',
          'color': tags.includes(c.id) ? 'white' : '',
          }"
        v-for="c in categorias">
          {{ c.nombre }}

        </div>

        <br/>
        <br/>
        Contenido:<br/>
        <vue-editor  ref="editor" :editorToolbar="customToolbar" :editorOptions="editorOptions" v-model="content"></vue-editor>
        <br/>
        <button v-on:click="enviarPost()" class="btn-std-2">Enviar Post</button>
      </div>
      <div class="loader-cnt" v-else>
            <LoaderBarra :width="100" :height="100"/>
      </div>
      </div>
      <div v-else-if="estado==1">
        <h1>¡Se ha enviado el post exitosamente! 🎉</h1>

      </div> 
      <div v-else>
        <h1>Ha ocurrido un error inesperado</h1>

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
// Basic Use - Covers most scenarios
import { VueEditor } from "vue2-editor";
import LoaderBarra from '@/components/LoaderBarra.vue'



export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    VueEditor,
    LoaderBarra,
    TopNav,
  },
  data() {
    return {

      loading:false,
      content: null,
      titulo:'',
      img_portada:'',
      abstract:'',
      estado:0,
      tags:[],
      customToolbar: [
        // Include your custom toolbar configuration
      // Add each group of options in its own array
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'], // remove formatting button
      ['link', 'image', 'video', 'formula'], // adding link, image, video, and formula buttons
      ['customImage'] // Your custom image insert button
      ],
      editorOptions: {
        // Add any Quill specific options here
      }

    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),

    enviarPost(){
      this.loading = true;
      axios.post(window.hostname+'api/superadmin/nuevo_post',
          {
            contenido:this.content,
            titulo:this.titulo,
            abstract:this.abstract,
            etiquetas:this.tags,
            img_portada:this.img_portada,
          })
          .then(response => {
            this.loading = false;
            this.estado = 1;
          })
          .catch(e=>{
            console.log(e)
            this.loading = false;
            this.estado = 2;

          })
    },
    addTag(value){
      const index = this.tags.indexOf(value);

      if (index === -1) {
    // If the value is not found, add it to the array
    this.tags.push(value);
  } else {
    // If the value is found, remove it from the array
    this.tags.splice(index, 1);
  }
    },  

    addCustomImageButtonHandler() {
      const customButton = document.querySelector('.ql-customImage');
      customButton.innerHTML = '<img src="https://cdn4.iconfinder.com/data/icons/social-media-black-white-2/600/Instagram_glyph_svg-512.png" alt="Image" style="max-width: 24px;" />'
      if (customButton) {
        customButton.addEventListener('click', () => {
          this.insertImageByUrl();
        });
      }
    },
    insertImageByUrl() {
  const url = prompt('Enter the image URL');
  if (url) {
    const range = this.$refs.editor.quill.getSelection(true); // Ensure getting the current selection
    if (range) {
      this.$refs.editor.quill.insertEmbed(range.index, 'image', url, 'user');
      this.$refs.editor.quill.setSelection(range.index + 1); // Move the cursor after the image
    }
    this.$refs.editor.quill.focus(); // Focus the editor
  }
}
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    this.$nextTick(() => {
      this.addCustomImageButtonHandler();
    });

    
  },
  watch:{

  }

}
</script>


<style scoped>



td{
  text-align: left;
}

.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

