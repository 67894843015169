<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        <p>Estos son todas las transferencias que se han hecho y registrado ultimamente.</p>
        <table class="tablex">
    <thead>
        <tr>
            <th>ID</th>
            <th>Monto</th>
            <th>Margen</th>
            <th>Nombre</th>
            <th>Rut</th>
            <th>Comprobante</th>
        </tr>
    </thead>
    <tbody>
      <template v-for="t, u in transferencias">
        <tr  >
            <td @click="toggleDetails('details'+(u+1))">{{t.id}}</td>
            <td @click="toggleDetails('details'+(u+1))">${{Intl.NumberFormat('de-DE').format(t.monto)}}</td>
            <td @click="toggleDetails('details'+(u+1))">{{t.margen_captacion*100}}%</td>
            <td @click="toggleDetails('details'+(u+1))">{{t.nombre}}</td>
            <td @click="toggleDetails('details'+(u+1))">{{t.rut}}</td>
            <td><button @click.prevent="descargar_comprobante(t.id)">Descargar</button></td>
        </tr>
        <tr  :id="'details'+(u+1)" class="details">
          <td style="padding: 0px;" colspan="6">
            <table style="margin: 0 auto; width: 100%;">
              <thead>
      <tr  >
      

        <th style="background-color: ;">Venta</th>
        <th>Orden ID</th>

        <th>Método de pago</th>
        <th>Fecha</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="p in t.pagos">

  
        <td>${{Intl.NumberFormat('de-DE').format(p.monto)}}</td>
        <td>{{p.orden_id}}</td>
        <td>{{ p.metodo_pago }}</td>
        <td> <span v-if="p.fecha_pagado">{{new Date(p.fecha_pagado.y, p.fecha_pagado.m-1, p.fecha_pagado.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</span><span v-else>Indeterminado</span></td>

      </tr>

    </tbody>
            </table>
          </td>
        </tr>
      </template>


    </tbody>
</table>


        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:true,
      transferencias:[],
    }
  },
  created(){
  this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

    toggleDetails(id) {
        const detailsRow = document.getElementById(id);
        detailsRow.style.display = detailsRow.style.display === 'table-row' ? 'none' : 'table-row';
    },
    descargar_comprobante(comprobante_id){
      axios({
        url: window.hostname+'api/superadmin/obtener_link_comprobante',
        method: 'POST',
        data: {
          comprobante_id:comprobante_id,
        }
      })
      .then( (response) =>  {

        const anchor = document.createElement('a');

// Set the href attribute to the download URL
anchor.href = response.data.link;

// Set the download attribute to the filename
anchor.download = 'comprobante.'+response.data.ext;

  // Add the anchor element to the document
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor element
  anchor.click();

  // Remove the anchor element from the document
  document.body.removeChild(anchor);         
      })
      .catch(e=>{
        console.log(e);
      })

    },
    initialize_(){

      this.loaded = false;
      axios.get(window.hostname+'api/superadmin/get_transferencias',)
      .then(response => 
      {
          this.loaded = true;
          this.transferencias = response.data.transferencias.reverse();

      })

      .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...", e)
      })
    },


  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    
  },
  watch:{

  }

}
</script>


<style scoped>
    .details {
        display: none;
    }
    body {
        font-family: Arial, sans-serif;
    }

    .tablex {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
    }

    .tablex th, .tablex td {
        text-align: left;
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    .tablex th {
      background-color: rgb(38, 53, 67);
        color: white;
    }

    .tablex tr:nth-child(even) {
        background-color: #f2f2f2;
    }



    button {
        background-color: #4CAF50;
        color: white;
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
    }

    button:hover {
        background-color: #45a049;
    }
    
td{
  text-align: left;
  cursor: pointer;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

