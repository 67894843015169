<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        
        <div v-if="$route.params.id == undefined">
       
      
          <h2 style="margin-bottom:30px;">Modificar Precios</h2>
        <div  v-for="c in categorias">
          <div  class="cat-titulo"> {{ c.nombre }}</div>
          <table style="margin-left: 0px; width: 400px; margin-bottom: 30px;">
        
            
              <tr v-for="s in servicios.filter(x=>{return x.categoria_id == c.id})">
                <td>{{ s.nombre }}</td>
                <td style="text-align: right !important; "> <router-link  :to="{name:'ModificarPrecios', params:{id:s.id}}"><button class="btn btn-std">Modificar</button></router-link></td>
              </tr>
            

          
        </table>
        </div> 
      </div>
      <div v-else>

        <table style="margin-left: 0;">
          <tr>
            <td><div class="cat-titulo">{{ servicio_calculated.nombre }}</div></td>
            <td> 
              <div v-if="!servicio_calculated.editando">{{ servicio_calculated.precio == undefined ? 'Sin asignar' : '$'+Intl.NumberFormat('de-DE').format(servicio_calculated.precio) }} <b-icon @click="servicio_calculated.editando = true; $forceUpdate()" icon="pen"></b-icon></div> 
              <div v-else><input v-model="servicio_calculated.precio" type="text"> <b-icon @click="servicio_calculated.editando = false; $forceUpdate(); guardar_servicio(servicio_calculated);" icon="check"></b-icon></div> 
            </td>
            <td>
              
            </td>
          </tr>
          
          <template v-for="cc in servicio_calculated.caracteristicas">
            <tr>
              <td class="cat-titulo">{{ cc.nombre }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-if="cc.opciones != undefined" v-for="o in cc.opciones">
              <td>{{ o.nombre }}</td>
              <td>

                  <div v-if="!o.editando">{{ o.precio == undefined ? 'Sin asignar' : '$'+Intl.NumberFormat('de-DE').format(o.precio) }} <b-icon @click="o.editando = true; $forceUpdate()" icon="pen"></b-icon></div> 
                  <div v-else><input v-model="o.precio" type="text"> <b-icon @click="o.editando = false; $forceUpdate(); guardar_opcion(o);" icon="check"></b-icon></div> 
                    
                
             
              </td> 

            </tr>  

          </template>

        </table>
      </div>
        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:false,
      profesional:{},
      servicio_calculated:{},
      s_id:-1,

    }
  },


  methods:{
    ...mapActions(['initialize']),
    guardar_opcion(o){
        this.loaded = false;
        axios.post(window.hostname+'api/admin/modificar_precio_opcion', 
        {profesional_id: this.$route.params.profesional_id, opcion_id:o.id, nuevo_precio:o.precio == '' ? -1 : o.precio})
        .then(response => 
        {
            this.loaded = true;
            console.log(response.data)            
            this.initialize_(this.$route.params.profesional_id)
            
   
        })
        .catch(z=>{this.loaded = true;})
    },
    guardar_servicio(o){
        this.loaded = false;
        axios.post(window.hostname+'api/admin/modificar_precio_servicio', 
        {profesional_id: this.$route.params.profesional_id, servicio_id:o.id, nuevo_precio:o.precio == '' ? -1 : o.precio})
        .then(response => 
        {
            this.loaded = true;
            console.log(response.data)            
            this.initialize_(this.$route.params.profesional_id)
            
   
        })
        .catch(z=>{this.loaded = true;})
    },
    async initialize_(){
      console.log("AAAAAAA", this.$route.params.profesional_id)
      if (typeof(this.$route.params.profesional_id === 'number'))
      {
        this.loaded = false;
        axios.post(window.hostname+'api/admin/get_profesional_precios', 
        {id: this.$route.params.profesional_id})
        .then(response => 
        {

            this.loaded = true;
            console.log(response.data)
            this.profesional =  response.data.profesional
            if(this.$route.params.id != undefined)
              this.recalcular_servicio(this.$route.params.id)
            
   
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
      }
    },
    recalcular_servicio(val){
      let new_obj_ = this.servicios.find(z=>{return z.id == val})
      let new_obj = Object.assign({}, new_obj_)
      var valor;

      for(var c = 0; c < new_obj.caracteristicas.length; c++){
        for (var o = 0; o < new_obj.caracteristicas[c].opciones.length; o++){
          valor =  this.profesional.precios.precio_opciones.find(l=>{return l.opcion_id == new_obj.caracteristicas[c].opciones[o].id});
          if (valor != undefined)
            new_obj.caracteristicas[c].opciones[o].precio =valor.valor
          else
           new_obj.caracteristicas[c].opciones[o].precio = undefined
          new_obj.caracteristicas[c].opciones[o].editando = false;
        }
      }
      new_obj.editando = false;
      valor = this.profesional.precios.precio_servicios.find(l=>{return l.servicio_id == new_obj.id});
      if (valor != undefined)
        new_obj.precio = valor.valor;
      else
        new_obj.precio = undefined

      this.servicio_calculated = new_obj
    },

  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'servicios']),
  },
  mounted(){
    this.initialize_(this.$route.params.profesional_id)

  },
  beforeRouteUpdate(to, from, next) {
    if(to.params.id != undefined)
      this.s_id = to.params.id
    next()
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    s_id(val){
      
        this.recalcular_servicio(val)
    }
  }

}
</script>


<style scoped>
.cat-titulo{
  font-weight: 700;
  font-size: 20px;
}
td{
  text-align: left;
}
.articulo-principal{
text-align: left;


}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

