<template>
  <!-- eslint-disable -->
<div>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" :width="width+'px'" :height="height+'px'" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<defs>
  <pattern id="ldio-ojump5hjuec-pattern" patternUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
    <g>
      <g transform="rotate(20 50 50) scale(1.2)">
        <rect x="-20" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="-10" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="0" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="10" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="20" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="30" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="40" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="50" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="60" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="70" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="80" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="90" y="-10" width="10" height="120" fill="#1486e7"></rect>
        <rect x="100" y="-10" width="10" height="120" fill="#ff8c00"></rect>
        <rect x="110" y="-10" width="10" height="120" fill="#ff8c00"></rect>
      </g>
      <animateTransform attributeName="transform" type="translate" values="0 0;26 0" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
    </g>
  </pattern>
</defs>
<rect rx="8" ry="8" x="10" y="41.5" stroke="#8f8f8f" stroke-width="2.5" width="80" height="17" fill="url(#ldio-ojump5hjuec-pattern)"></rect>
</svg>
</div>
</template>

<script>
 /* eslint-disable */



export default {
  name: 'LoaderTuercas',
  props: {
      height: Number,
      width: Number,
     },

  data() {
    return {

    }
  },
  created(){
  

  },


  computed:{

  },



}
</script>


<style scoped>

</style>

