import { render, staticRenderFns } from "./ModalImg.vue?vue&type=template&id=274c7773&scoped=true&"
import script from "./ModalImg.vue?vue&type=script&lang=js&"
export * from "./ModalImg.vue?vue&type=script&lang=js&"
import style0 from "./ModalImg.vue?vue&type=style&index=0&id=274c7773&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274c7773",
  null
  
)

export default component.exports