<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        <h2 style="margin-bottom:20px;">Cotización <b-icon icon="newspaper"></b-icon></h2>
          <!--
        <div v-for="p in profesionales">
            <div>
              {{(p.profesional.nombre + ' ' + p.profesional.apellido)}}
            </div>
            <div v-for="h in p.horarios">
              {{(timeConvert(h.inicio) +' - '+timeConvert(h.termino))}} en <span v-for="c in h.comunas">{{c.nombre}}  </span>
            </div>

          </div>
          -->

          
          <div style="" class="input-cont">
                  <input v-model="nombre_" @focus="focused_nombre_ = true;" @blur="focused_nombre_ = false;" type="text">
                  <div :class="{'subido':focused_nombre_ || nombre_ != ''}" class="input-label">Nombre</div>
                  </div>

                  <div style="" class="input-cont">
                  <input placeholder="notas" v-model="notas_" type="textbox">
  
                  </div>


                  <div  style="text-align: left">
                    <hr />
                   <h4> Agregar servicio</h4>
                    <br />
                    <table style="margin-left:0px;">
                      <tr>
                        <td>Nombre</td>
                        <td><input v-model="servicio_nombre" type="text"></td>  
                      </tr>
                      <tr>
                        <td>descripcion</td>
                        <td><input v-model="servicio_descripcion" type="text"></td>  
                      </tr>
                      <tr>
                        <td>Precio</td>
                        <td><input v-model="servicio_precio" type="number"></td>  
                      </tr>
                      <tr>
                        <td>¿Es presupuesto?</td>
                        <td><input type="checkbox" v-model="servicio_presupuesto" /></td>  
                      </tr>
                    </table>

                  </div>
                  <br />
                    <button v-on:click="agregar_servicio()" class="btn btn-info">Agregar servicio</button>
                    <div>
                    </div>
                    <br />
                    <div style="border: 1px solid rgba(0,0,0,0.2); border-radius: 4px; padding: 10px; display: inline-block;" v-if="servicios_agregados.length > 0">
                      
                     <span style="font-weight: 700;"> Servicios agregados:</span>
                     <br/>
                      <div v-for="s, i in servicios_agregados">
                        <span style="padding: 0px 4px; color:green; font-size: 14px; border: 1px solid green; border-radius: 4px;" v-if="s.es_presupuesto == 1">Presupuesto</span>
                        
                        {{ s.nombre }}: ${{Intl.NumberFormat('de-DE').format( s.precio )}} 
                        <b-icon v-on:click="servicios_agregados.splice(i, 1)" style="cursor: pointer; color:#FF4136" icon="x-circle"></b-icon> 
                      </div>
                      <br />
                      <span style="font-weight: 700;">
                        Total orden: ${{ Intl.NumberFormat('de-DE').format(servicios_agregados.reduce((x, y)=>{return parseInt(x) + parseInt(y.precio)}, 0) )}}
                      </span>  
                    </div>
                    <br />

                  

                      <hr />
            <h4>Profesional:</h4>
            <div style="text-align: left;">
          <table style="margin-top:20px; margin-left: 0px;">
                  <tr>
                    <td style="width:20px;"></td>
                  <td>Nombre</td>
                  <td>Estado</td>  
                  </tr>
                  <tr v-for="p in profesionales">
                    <td><input type="radio" :value="p.id" v-model="profesional_selected" name="radio"></input></td>
                    <td >{{(p.nombre + ' '+p.apellido)}}</td>
                    <td style="width:150px;" :style="{'color':p.disp == 'disponible' ? '#2ECC40' : p.disp == 'sin horario' ? '#FFDC00' : p.disp == 'bloqueado' || p.disp == 'ocupado' ? '#FF4136' : ''}"> 
                      <div v-if="(p.disp != 'cargando...')">{{p.disp}} </div>
                      <div v-else>
                        <div  style="color:rgba(0,0,0,0.8); display: inline-block; font-size: 14px;">Cargando...</div>
                      <div class="spinner-border spinner-border spinner-border-sm"
                      style="
                        margin-left: 10px;
                        margin-right: 14px;
                        margin-top: 2px;
                        margin-bottom: 2px;
                        color: grey;
                      "></div>
                      </div>
                    </td>

                  </tr>
                </table>
              </div>
              <button v-on:click="enviar()" class="btn btn-primary">Descargar</button>
                
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:false,
      profesionales:[],

      nombre_:'',
    apellido_:'',
    notas_:'',


    profesional_selected:0,

    servicios_agregados:[],
    servicio_nombre:'',
    servicio_descripcion:'',
    servicio_precio:0,
    servicio_presupuesto:1,






    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
  },

  methods:{
    ...mapActions(['initialize']),
    agregar_servicio(){
      this.servicios_agregados.push({'nombre':this.servicio_nombre, 'descripcion':this.servicio_descripcion, 'precio':this.servicio_precio, 'es_presupuesto':this.servicio_presupuesto})
    },
    enviar(){

          axios.post(window.hostname+'api/superadmin/descargar_cotizacion',
          {
          pro_id:this.profesional_selected,        
          servicios:this.servicios_agregados,
          nombre:this.nombre_,
          notas:this.notas_,
      
          }, 
          {responseType: 'blob'}
          )

    .then((response) => {
    // Create a new Blob object using the response data of the file
    const file = new Blob(
        [response.data], 
        { type: 'application/pdf' } // Replace with the MIME type of your file
    );

    // Create a link using the Blob
    const fileURL = URL.createObjectURL(file);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'file.pdf'); // Replace with your desired file name
    document.body.appendChild(fileLink);

    // Programmatically click the link to trigger the download
    fileLink.click();

    // Clean up and remove the link once done
    fileLink.parentNode.removeChild(fileLink);
    this.initialize_()
            this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: '¡Se ha agendado exitosamente!',
            type:'success'
            });
}).catch(error => {
  this.$notify({
            group: 'foo',
            title: '¡Error!',
            text: '¡Ha ocurrido un error!',
            type:'error'
            });   
    console.error("There was an error downloading the file:", error);
});



      },

    getFlagUrl(pic) {
    return require('../../assets/banderas/'+pic)
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.get(window.hostname+'/api/superadmin/init_orden_manual',)
        .then(response => 
        {
            this.loaded = true;
            this.profesionales = response.data.profesionales;
   
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
      }
    },
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    var hoy = new Date()

  },
  watch:{

  }

}
</script>


<style scoped>
.input-cont{
  position:relative;
  text-align:left;
  max-width:400px;
  margin-bottom:40px;
}
.input-cont input{
  padding: 4px;
  border:none;
  border-bottom:1px solid rgba(0,0,0,0.4);
  width:100%;
  outline:none;
}
.input-label{
  position:absolute;
  top:3px;
  left:6px;
  pointer-events: none;
  color:rgba(0,0,0,0.6);
  transition: 0.2s;
}
.subido{
  top:-12px;
  left:0px;
  font-size: 12px;
  color:#FF8C00;
}
.subido2{
  top:-16px;
  left:-84px;
  font-size: 12px;
  color:#FF8C00;
}
.btn-casa-depto{
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #FF8C00;
  margin-right:10px;
  color:#FF8C00;
  border-radius: 5px;
  cursor: pointer;
}
.btn-casa-depto:hover{
  color:white;
  background: #FF8C00;
}
.btn-casa-depto-s{
  color:white;
  background: #FF8C00;
}

td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

